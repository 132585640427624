<form (ngSubmit)="submit()" [formGroup]="configForm" nz-form>
  <nz-alert [nzMessage]="'SIMULATOR.SOLVER.Disclaimer' | translate" nzShowIcon nzType="info"></nz-alert>
  <nz-form-item nz-row>
    <nz-form-control *ngIf="seed?.length > 0" [nzOffset]="6" [nzSpan]="14">
      <label formControlName="seeded" nz-checkbox>
        <span>{{'SIMULATOR.SOLVER.Use_rotation_as_seed' | translate}}</span>
      </label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item class="register-area" nz-row>
    <nz-form-control [nzOffset]="6" [nzSpan]="14">
      <button [nzLoading]="loading" nz-button nzType="primary">
        {{'SIMULATOR.Solve_rotation' | translate}}
      </button>
    </nz-form-control>
  </nz-form-item>
</form>
