export enum DataType {
  NONE,
  CRAFTED_BY,
  TRADE_SOURCES,
  VENDORS,
  REDUCED_FROM,
  DESYNTHS,
  INSTANCES,
  GATHERED_BY,
  GARDENING,
  VOYAGES,
  DROPS,
  ALARMS,
  MASTERBOOKS,
  TREASURES,
  FATES,
  VENTURES,
  TRIPLE_TRIAD_DUELS,
  TRIPLE_TRIAD_PACK,
  QUESTS,
  ACHIEVEMENTS,
  REQUIREMENTS
}
