<div class="tooltip-container">
  <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <div class="icon">
      <img alt="{{item | xivapiL12n: 'items' | i18n}}" src="https://xivapi.com{{item.Icon}}">
    </div>
    <div class="name" fxLayout="column">
      <span [appItemRarity]="item.ID">{{item | xivapiL12n: 'items' | i18n}}</span>
      <span *ngIf="item.ItemKind" class="item-kind">{{item.ItemKind | xivapiL12n: 'ItemKinds' | i18n}} <span
        *ngIf="item.ItemSearchCategory">> {{item.ItemSearchCategory | xivapiL12n: 'itemSearchCategories' | i18n}}</span></span>
      <i class="db-tip">{{'DB.Click_to_navigate' | translate}}</i>
    </div>
  </div>
  <div *ngIf="item.Description !== undefined && item.Description.length > 0" class="description">
    <span [innerHtml]="item | xivapiL12n: 'itemDescriptions':'Description' | i18n | xivUIText"></span>
  </div>
  <div *ngIf="patch" class="patch">
    <span>{{patch | xivapiI18n}}</span>
  </div>
  <div *ngIf="item.ClassJobCategory !== null" class="class-jobs" nz-row>
    <div [nzXs]="12" nz-col>
      <p class="detail-name">
        {{'TOOLTIP.Class_job' | translate}}
      </p>
    </div>
    <div [nzXs]="12" nz-col>
      <p class="detail-value">
        {{item.ClassJobCategory | xivapiL12n: 'jobCategories' | i18n}}
      </p>
    </div>
  </div>
  <div class="main-attributes details">
    <div *ngFor="let row of mainAttributes" nz-row nzGutter="10">
      <div [nzXs]="12" nz-col>
        <p *ngIf="!row.requiresPipe" class="detail-name">
          {{row.name | translate}}
        </p>
        <p *ngIf="row.requiresPipe" class="detail-name">
          {{row.name | i18n}}
        </p>
      </div>
      <div [nzXs]="12" nz-col>
        <p class="detail-value">
          {{row.value}}
          <span *ngIf="item.CanBeHq === 1 && row.valueHq && row.valueHq !== row.value" class="hq-value">
            ({{row.valueHq}})
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="details">
    <div *ngFor="let row of stats" nz-row nzGutter="10">
      <div [nzXs]="12" nz-col>
        <p *ngIf="!row.requiresPipe" class="detail-name">
          {{row.name | translate}}
        </p>
        <p *ngIf="row.requiresPipe" class="detail-name">
          {{row.name | i18n}}
        </p>
      </div>
      <div [nzXs]="12" nz-col>
        <p class="detail-value">
          {{row.value}}
          <span *ngIf="item.CanBeHq" class="hq-value">
            <span *ngIf="item.ItemFood === undefined; else itemFoodHQ">({{row.valueHq}})</span>
            <ng-template #itemFoodHQ>
              {{row.valueHq}}
            </ng-template>
          </span>
        </p>
      </div>
    </div>
    <div *ngIf="item.ItemSpecialBonus?.ID > 1">
      <b class="set-bonus">{{item.ItemSpecialBonus | xivapiI18n}}: {{item.ItemSeries | xivapiI18n}}</b>
      <ul>
        <li *ngFor="let bonus of item.SetBonuses"> {{'DB.ITEM.X_Equipped' | translate:{ amount: bonus.amountRequired } }}
          {{bonus.baseParam | baseParamName | i18n}} +{{bonus.value}}</li>
      </ul>
    </div>
  </div>
  <div class="xivapi">xivapi.com</div>
</div>
