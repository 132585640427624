<div class="tooltip-container">
  <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <div class="icon">
      <img alt="{{fish | xivapiI18n}}" src="https://xivapi.com{{fish.Icon}}">
    </div>
    <div class="name" fxLayout="column">
      <span>{{fish | xivapiI18n}}</span>
      <span class="item-kind">{{fish.ItemKind | xivapiL12n: 'ItemKinds' | i18n}}</span>
    </div>
  </div>
  <div *ngIf="fish.Description !== undefined && fish.Description.length > 0" class="description">
    <span [innerHtml]="fish | xivapiI18n: 'Description' | xivUIText"></span>
  </div>
  <div *ngIf="getFshData(fish) as nodes" class="details">
    <div *ngFor="let node of nodes; let last = last">
      <app-node-details [node]="node" [showAlarmsIntegration]="false"></app-node-details>
      <div fxLayout="column" fxLayoutGap="5px">
        <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
          <img *ngIf="row.folklore !== undefined" [src]="row.folklore | lazyIcon" class="item-icon small-icon">
          <img *ngIf="node.snagging" [nzTooltipTitle]="'GATHERING_LOCATIONS.Snagging' | translate" alt="" nz-tooltip
               src="./assets/icons/status/snagging.png">
        </div>
        <div fxLayout="row" fxLayoutAlign="flex-start">
          <div *ngFor="let bait of node.baits; let index = index; let last = last" fxLayout="row" fxLayoutAlign="center center"
               fxLayoutGap="5px">
            <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
              <div class="icon-container">
                <img [src]="bait | lazyIcon" class="item-icon small-icon">
                <img *ngIf="index > 0" class="hq-overlay small-icon"
                     src="https://www.garlandtools.org/db/images/HQOverlay.png">
              </div>
              <div>{{bait | itemName | i18n}}</div>
            </div>
            <i *ngIf="!last" nz-icon nzType="caret-right" theme="outline"></i>
          </div>
        </div>
        <div *ngIf="node.predators && node.predators.length > 0" fxLayout="row" fxLayoutAlign="flex-start center"
             fxLayoutGap="10px">
          <img [nzTooltipTitle]="'GATHERING_LOCATIONS.Predators' | translate" alt="" nz-tooltip
               src="./assets/icons/status/predators.png">
          <div *ngFor="let predator of node.predators" fxLayout="row" fxLayoutAlign="flex-start center"
               fxLayoutGap="5px">
            <div>{{predator.amount}}x</div>
            <img class="small-icon" src="{{predator.id | lazyIcon}}"/>
            <div>{{predator.id | itemName | i18n}}</div>
          </div>
        </div>
      </div>
      <nz-divider *ngIf="!last"></nz-divider>
    </div>
  </div>

</div>
