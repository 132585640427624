export const lazyFilesList = {
  'achievements': {
    'fileName': 'achievements.json',
    'hashedFileName': 'achievements.da870218102a6995ceb05fd9985eb21708d4af4e.json'
  },
  'actionCdGroups': {
    'fileName': 'action-cd-groups.json',
    'hashedFileName': 'action-cd-groups.fb5f43368d633558dcafa6aed6fe5aad2473be12.json'
  },
  'actionIcons': {
    'fileName': 'action-icons.json',
    'hashedFileName': 'action-icons.218a087a57ba565d65a96a5fe48c396f5739d34a.json'
  },
  'actionTimeline': {
    'fileName': 'action-timeline.json',
    'hashedFileName': 'action-timeline.98c5fbd71abd996d77d8afc192440cd777fa6c87.json'
  },
  'actions': {
    'fileName': 'actions.json',
    'hashedFileName': 'actions.293ad75619494cdb8365606aeebea3d573117550.json'
  },
  'aetherytes': {
    'fileName': 'aetherytes.json',
    'hashedFileName': 'aetherytes.4a6e508e0117402aa9ec5929f37c13ac07ed33a3.json'
  },
  'airshipParts': {
    'fileName': 'airship-parts.json',
    'hashedFileName': 'airship-parts.63a650742a1b9179f7eb78bd5625baff84372f17.json'
  },
  'airshipVoyages': {
    'fileName': 'airship-voyages.json',
    'hashedFileName': 'airship-voyages.bd0ef39dcd833a1119919cb084807b19b25cfb0e.json'
  },
  'baitItems': {
    'fileName': 'bait-items.json',
    'hashedFileName': 'bait-items.121b89efbda69c8736ec09fcf451999fad373283.json'
  },
  'baseParams': {
    'fileName': 'base-params.json',
    'hashedFileName': 'base-params.b19b64cfa5799b10e3ed022fcd0e42743213a5af.json'
  },
  'bigFishes': {
    'fileName': 'big-fishes.json',
    'hashedFileName': 'big-fishes.986eef0ff869b0883210840ab62fea86bf1af70f.json'
  },
  'classJobsModifiers': {
    'fileName': 'class-jobs-modifiers.json',
    'hashedFileName': 'class-jobs-modifiers.3958e9d7a4521a6a0921e79e9c1933619e7c87e1.json'
  },
  'collectablesShopItemGroup': {
    'fileName': 'collectables-shop-item-group.json',
    'hashedFileName': 'collectables-shop-item-group.88151e96273b36f1f0724eddf4bc73a1127e29d7.json'
  },
  'collectablesShops': {
    'fileName': 'collectables-shops.json',
    'hashedFileName': 'collectables-shops.b4bedf0fe4e2becf7b1a5aa36be52dcecdbfcbf2.json'
  },
  'collectables': {
    'fileName': 'collectables.json',
    'hashedFileName': 'collectables.c982e413b6675c6da8c5f551b6059bc4c5698cf6.json'
  },
  'craftActions': {
    'fileName': 'craft-actions.json',
    'hashedFileName': 'craft-actions.4d17f961b4a8485ca4164876b9495cecbd238e56.json'
  },
  'craftingLogPages': {
    'fileName': 'crafting-log-pages.json',
    'hashedFileName': 'crafting-log-pages.ab1991972d8320fb989e2625112f5fad06cd6d22.json'
  },
  'craftingLog': {
    'fileName': 'crafting-log.json',
    'hashedFileName': 'crafting-log.8dd7528f59e97e85a5c76a4bbc699afa7f1026db.json'
  },
  'desynth': {
    'fileName': 'desynth.json',
    'hashedFileName': 'desynth.ac38bc3d7dc66ae0234e1dc540a450fad7f3771b.json'
  },
  'diademTerritory': {
    'fileName': 'diadem-territory.json',
    'hashedFileName': 'diadem-territory.b11afe3bfd6f5164611ae56e3899b673f9cde59f.json'
  },
  'equipSlotCategories': {
    'fileName': 'equip-slot-categories.json',
    'hashedFileName': 'equip-slot-categories.d9b72a4217038e46bd553a788cea4ae2e6f142df.json'
  },
  'equipment': {
    'fileName': 'equipment.json',
    'hashedFileName': 'equipment.6a2c22bd12f4a1998b1c09ae7c1b7f77d5fb2dfd.json'
  },
  'exVersions': {
    'fileName': 'ex-versions.json',
    'hashedFileName': 'ex-versions.fdb716d67a321c350839f103bffdb41bd3b7e35a.json'
  },
  'extractableItems': {
    'fileName': 'extractable-items.json',
    'hashedFileName': 'extractable-items.894c1d2f956864e06a882db4ceaeb0eb1a2d5bad.json'
  },
  'fates': {
    'fileName': 'fates.json',
    'hashedFileName': 'fates.ba11afaf27c27ad508e1b55486d99447b492807e.json'
  },
  'fishParameter': {
    'fileName': 'fish-parameter.json',
    'hashedFileName': 'fish-parameter.38400116706754268bb5c5417f938daf304c1d6a.json'
  },
  'fishes': {
    'fileName': 'fishes.json',
    'hashedFileName': 'fishes.976c672e36c68f20affaf854b55cd6721e98ba7a.json'
  },
  'fishingLog': {
    'fileName': 'fishing-log.json',
    'hashedFileName': 'fishing-log.4eb0b4a869ca1691fe0cec6f8d2c126f29024311.json'
  },
  'fishingSpots': {
    'fileName': 'fishing-spots.json',
    'hashedFileName': 'fishing-spots.1e0bc425c1ec63fcc13e26b754b56802e47306da.json'
  },
  'foods': {
    'fileName': 'foods.json',
    'hashedFileName': 'foods.5c05a9766922c56a6b025c5f0bde7667ecdf4ed7.json'
  },
  'freeCompanyActions': {
    'fileName': 'free-company-actions.json',
    'hashedFileName': 'free-company-actions.f4f1953dca9cb18936dad61766fb4d9925179835.json'
  },
  'gatheringBonuses': {
    'fileName': 'gathering-bonuses.json',
    'hashedFileName': 'gathering-bonuses.5a70203bb737f9ce35f6d11541735dec9f61d8a5.json'
  },
  'gatheringItems': {
    'fileName': 'gathering-items.json',
    'hashedFileName': 'gathering-items.28d5e051ef947ce061fe2e3d3173bbcf49f7fbd7.json'
  },
  'gatheringLogPages': {
    'fileName': 'gathering-log-pages.json',
    'hashedFileName': 'gathering-log-pages.61cadc73c813c8e5b6a78e054c874686f69f81d6.json'
  },
  'gatheringPointToNodeId': {
    'fileName': 'gathering-point-to-node-id.json',
    'hashedFileName': 'gathering-point-to-node-id.6267bc263565ede37f2270cce6df5adbae8f5e4e.json'
  },
  'hqFlags': {
    'fileName': 'hq-flags.json',
    'hashedFileName': 'hq-flags.3b0a0b31732b02ab3fb66ca791e6be713c342c49.json'
  },
  'hunts': {
    'fileName': 'hunts.json',
    'hashedFileName': 'hunts.dbc060aaba901f8deb805c9406852b3e6127b60c.json'
  },
  'hwdInspections': {
    'fileName': 'hwd-inspections.json',
    'hashedFileName': 'hwd-inspections.1699f937a336616a37d9f68952a43c7a8aed7ade.json'
  },
  'hwdPhases': {
    'fileName': 'hwd-phases.json',
    'hashedFileName': 'hwd-phases.a976c8eaab2b2724de1fb61b845e543beb750492.json'
  },
  'ilvls': {
    'fileName': 'ilvls.json',
    'hashedFileName': 'ilvls.dc36818205519c0b1ca5d274df04b00fbce28183.json'
  },
  'instances': {
    'fileName': 'instances.json',
    'hashedFileName': 'instances.e721fa5528862f1b2901d2729ea099c0c3a546a0.json'
  },
  'itemEquipSlotCategory': {
    'fileName': 'item-equip-slot-category.json',
    'hashedFileName': 'item-equip-slot-category.1ef4c8f42189fe8385cc31bdc7c544204aa4987d.json'
  },
  'itemIcons': {
    'fileName': 'item-icons.json',
    'hashedFileName': 'item-icons.b6d1401088fe6a26a595ac647d0193f46adde6c2.json'
  },
  'itemLevel': {
    'fileName': 'item-level.json',
    'hashedFileName': 'item-level.adcca5b87dfca9bdda513a1b61daf329fe182b46.json'
  },
  'itemMainAttributes': {
    'fileName': 'item-main-attributes.json',
    'hashedFileName': 'item-main-attributes.e9469327abf655b56f7a7a4dde877d122cfa499e.json'
  },
  'itemMeldingData': {
    'fileName': 'item-melding-data.json',
    'hashedFileName': 'item-melding-data.7b7643dea514b9f440fc458bdac790d72a5597e5.json'
  },
  'itemPatch': {
    'fileName': 'item-patch.json',
    'hashedFileName': 'item-patch.3ab98cfcf8ef9ce9f2b5f9738c30f1ca041b4770.json'
  },
  'itemSeries': {
    'fileName': 'item-series.json',
    'hashedFileName': 'item-series.2c464bc51edf240800b13aaf53d8fd789656ba4f.json'
  },
  'itemSetBonuses': {
    'fileName': 'item-set-bonuses.json',
    'hashedFileName': 'item-set-bonuses.dbe71cd68ae0348047f70fd8845b0a9e39f8e18e.json'
  },
  'itemStats': {
    'fileName': 'item-stats.json',
    'hashedFileName': 'item-stats.32a7c56ad2c287dda48a771931eca83c50129efb.json'
  },
  'items': {
    'fileName': 'items.json',
    'hashedFileName': 'items.9d15b14de742993ad055eeaa68478622ce9ec89e.json'
  },
  'jobAbbr': {
    'fileName': 'job-abbr.json',
    'hashedFileName': 'job-abbr.ea8eb7d72f2dda1e664033afe42a0580027ec573.json'
  },
  'jobCategories': {
    'fileName': 'job-categories.json',
    'hashedFileName': 'job-categories.b6584db98a8a5a5db3e8fb6521512236bf4bd114.json'
  },
  'jobName': {
    'fileName': 'job-name.json',
    'hashedFileName': 'job-name.318b03559ab98b55cc76694771d4dc567266d60c.json'
  },
  'leves': {
    'fileName': 'leves.json',
    'hashedFileName': 'leves.d6e6309ea0b51b5983857204eb22ae5a3a2f69f8.json'
  },
  'maps': {
    'fileName': 'maps.json',
    'hashedFileName': 'maps.30d2a3d2d3cecc8aedc573d3acf17b8921980232.json'
  },
  'marketItems': {
    'fileName': 'market-items.json',
    'hashedFileName': 'market-items.1bd18cd1753d60abce98080ce4735cf10d03a2f9.json'
  },
  'materias': {
    'fileName': 'materias.json',
    'hashedFileName': 'materias.902010e1a6441d0427f7d4d157a027d4001076af.json'
  },
  'medicines': {
    'fileName': 'medicines.json',
    'hashedFileName': 'medicines.c87e29705c6d0075576b4140dc4e5ab3842dacff.json'
  },
  'mobs': {
    'fileName': 'mobs.json',
    'hashedFileName': 'mobs.970645406e49e92f3ea156e8c4739ce6a4ddcb82.json'
  },
  'monsters': {
    'fileName': 'monsters.json',
    'hashedFileName': 'monsters.767549837879c0c736f9bb326f33bdb1c29ba25d.json'
  },
  'nodes': {
    'fileName': 'nodes.json',
    'hashedFileName': 'nodes.7253ee322111091b17793cf94ce71286569ace20.json'
  },
  'notebookDivisionCategory': {
    'fileName': 'notebook-division-category.json',
    'hashedFileName': 'notebook-division-category.d6d5069958116a24cba7d2ab49ab2fdaae056bed.json'
  },
  'notebookDivision': {
    'fileName': 'notebook-division.json',
    'hashedFileName': 'notebook-division.82766c27232bd3f888217bf4457770a56634cc7e.json'
  },
  'npcs': {
    'fileName': 'npcs.json',
    'hashedFileName': 'npcs.4ed459c7b372d1df6fad40bb69a0274cc6a222d0.json'
  },
  'paramGrow': {
    'fileName': 'param-grow.json',
    'hashedFileName': 'param-grow.559e8c89cde60f82ff86f62b8605f991d06cef49.json'
  },
  'patchContent': {
    'fileName': 'patch-content.json',
    'hashedFileName': 'patch-content.ff7c164b1b2c091983e1bcd902009a9fea473c47.json'
  },
  'places': {
    'fileName': 'places.json',
    'hashedFileName': 'places.bfd49335d724ebed9bea6afa80e2340633661d9d.json'
  },
  'quests': {
    'fileName': 'quests.json',
    'hashedFileName': 'quests.549fe7c5888cdfde4a5871d3924b6e1a7f91e6c4.json'
  },
  'races': {
    'fileName': 'races.json',
    'hashedFileName': 'races.93af5732382917ac64fdfb3af264756ea49cabc3.json'
  },
  'rarities': {
    'fileName': 'rarities.json',
    'hashedFileName': 'rarities.453d60f902903ccdd75ba356ef241a678bf01526.json'
  },
  'recipesIngredientLookup': {
    'fileName': 'recipes-ingredient-lookup.json',
    'hashedFileName': 'recipes-ingredient-lookup.aeecc0a8d98cd0ecce9f9f8c015a4b824a7d5bab.json'
  },
  'recipes': {
    'fileName': 'recipes.json',
    'hashedFileName': 'recipes.14b3c4348652192a7ea96cd9bf639bae767ee96b.json'
  },
  'reduction': {
    'fileName': 'reduction.json',
    'hashedFileName': 'reduction.0c30743ac06052df9cf1ae55dd93a601722beeda.json'
  },
  'retainerTasks': {
    'fileName': 'retainer-tasks.json',
    'hashedFileName': 'retainer-tasks.f39b2fb2c687fdcf05c92bf6e921d72609982fc1.json'
  },
  'shops': {
    'fileName': 'shops.json',
    'hashedFileName': 'shops.24cbf0294fa631a0ae9a63b911edd214e0c662cd.json'
  },
  'spearFishingLog': {
    'fileName': 'spear-fishing-log.json',
    'hashedFileName': 'spear-fishing-log.ba497804b7e8f537ab38a06c90ef6824a345dea5.json'
  },
  'spearFishingNodes': {
    'fileName': 'spear-fishing-nodes.json',
    'hashedFileName': 'spear-fishing-nodes.fa4d5ba1960298b942bf1fc3ae54d185106f13ea.json'
  },
  'stackSizes': {
    'fileName': 'stack-sizes.json',
    'hashedFileName': 'stack-sizes.739c150a89d4bd01c0f3118d09e42c2d4229affe.json'
  },
  'statuses': {
    'fileName': 'statuses.json',
    'hashedFileName': 'statuses.afeecd77a624cdfe3b8bcc03527f9e71acba5c39.json'
  },
  'submarineParts': {
    'fileName': 'submarine-parts.json',
    'hashedFileName': 'submarine-parts.2c5938262b92156a0282afedca838f9c09617da6.json'
  },
  'submarineRanks': {
    'fileName': 'submarine-ranks.json',
    'hashedFileName': 'submarine-ranks.249f021082749ef25cca498f7483775d75e23898.json'
  },
  'submarineVoyages': {
    'fileName': 'submarine-voyages.json',
    'hashedFileName': 'submarine-voyages.40df3c347147a6c6b4d223f57086e86b1062be9c.json'
  },
  'territoryLayers': {
    'fileName': 'territory-layers.json',
    'hashedFileName': 'territory-layers.2cc30514c4e9d2fd56d237eaebaf28ec4c6cc1bd.json'
  },
  'tradeFlags': {
    'fileName': 'trade-flags.json',
    'hashedFileName': 'trade-flags.131d37ccae0b63bfbc14fb67a92e1d9bb63cea14.json'
  },
  'traits': {
    'fileName': 'traits.json',
    'hashedFileName': 'traits.152034c869aef9889a1a5800efcfae8e300bb1fb.json'
  },
  'tribes': {
    'fileName': 'tribes.json',
    'hashedFileName': 'tribes.58dbbb6da73de42faab2e658b27f3b3dbd934834.json'
  },
  'tripleTriadRules': {
    'fileName': 'triple-triad-rules.json',
    'hashedFileName': 'triple-triad-rules.91c64a1c0d616c54e43064535023080c1daef0c2.json'
  },
  'ventures': {
    'fileName': 'ventures.json',
    'hashedFileName': 'ventures.31ca9b7c43c31bd039889a1247d56fde862eeb23.json'
  },
  'weathers': {
    'fileName': 'weathers.json',
    'hashedFileName': 'weathers.7b71f10c9724728ed657ce22539d125378155e0c.json'
  },
  'koAchievementDescriptions': {
    'fileName': '/ko/ko-achievement-descriptions.json',
    'hashedFileName': '/ko/ko-achievement-descriptions.cca2f95c268e59a6872b138a239a88a746ddf12a.json'
  },
  'koAchievements': {
    'fileName': '/ko/ko-achievements.json',
    'hashedFileName': '/ko/ko-achievements.d2f3f37c3cdff89b85b7807b2b345cc4adc62138.json'
  },
  'koActionCategories': {
    'fileName': '/ko/ko-action-categories.json',
    'hashedFileName': '/ko/ko-action-categories.1a7584f46d4b1b32950ff1650d6bd9187de0dec2.json'
  },
  'koActionDescriptions': {
    'fileName': '/ko/ko-action-descriptions.json',
    'hashedFileName': '/ko/ko-action-descriptions.9f664e7120634dd6a5b24a433c40d5fb0dc890f7.json'
  },
  'koActions': {
    'fileName': '/ko/ko-actions.json',
    'hashedFileName': '/ko/ko-actions.360cb133e19c8e82f25c420f3d12931331034287.json'
  },
  'koBaseParams': {
    'fileName': '/ko/ko-base-params.json',
    'hashedFileName': '/ko/ko-base-params.2162eb1fc5babe29d1f7de203efcdfbd8ba79f44.json'
  },
  'koBeastReputationRanks': {
    'fileName': '/ko/ko-beast-reputation-ranks.json',
    'hashedFileName': '/ko/ko-beast-reputation-ranks.338d67bebd93f99c1b5c07eec5be29c542b3b18c.json'
  },
  'koContentTypes': {
    'fileName': '/ko/ko-content-types.json',
    'hashedFileName': '/ko/ko-content-types.57926f92f2ad7782873681cf6cb9de1ad40b9324.json'
  },
  'koCraftActions': {
    'fileName': '/ko/ko-craft-actions.json',
    'hashedFileName': '/ko/ko-craft-actions.2d9ee8c4a91fe55e5637867d4bd41c06563c74cb.json'
  },
  'koCraftDescriptions': {
    'fileName': '/ko/ko-craft-descriptions.json',
    'hashedFileName': '/ko/ko-craft-descriptions.f933731f99bbedebf23504a2b051814b78db77ff.json'
  },
  'koEventItems': {
    'fileName': '/ko/ko-event-items.json',
    'hashedFileName': '/ko/ko-event-items.49be62761559596c8af95d33ebcadc5590a013ba.json'
  },
  'koExVersions': {
    'fileName': '/ko/ko-ex-versions.json',
    'hashedFileName': '/ko/ko-ex-versions.fc538320ba871f63021e8defe8c385461a6639c0.json'
  },
  'koFates': {
    'fileName': '/ko/ko-fates.json',
    'hashedFileName': '/ko/ko-fates.b717f4a3f98d472a931b5be7869b81bd24e0f251.json'
  },
  'koFreeCompanyActions': {
    'fileName': '/ko/ko-free-company-actions.json',
    'hashedFileName': '/ko/ko-free-company-actions.1cecec9f4f3c5b1f80ce6c3138f9285c5e29bf45.json'
  },
  'koGatheringBonuses': {
    'fileName': '/ko/ko-gathering-bonuses.json',
    'hashedFileName': '/ko/ko-gathering-bonuses.829e1b8ca88c0a9373bcac15e9f3493354e578e8.json'
  },
  'koGatheringTypes': {
    'fileName': '/ko/ko-gathering-types.json',
    'hashedFileName': '/ko/ko-gathering-types.dbb1f886fe1e8f344f144cdb17f373e2fb5c840a.json'
  },
  'koInstanceDescriptions': {
    'fileName': '/ko/ko-instance-descriptions.json',
    'hashedFileName': '/ko/ko-instance-descriptions.2a88e45b3d13c38737ff1436d86941cea7fb0587.json'
  },
  'koInstances': {
    'fileName': '/ko/ko-instances.json',
    'hashedFileName': '/ko/ko-instances.3728e92730541475588ec216b8e720bf50fb0fbd.json'
  },
  'koItemDescriptions': {
    'fileName': '/ko/ko-item-descriptions.json',
    'hashedFileName': '/ko/ko-item-descriptions.529e5dc29185b092ea02fcd9b9404209999e6006.json'
  },
  'koItemKinds': {
    'fileName': '/ko/ko-item-kinds.json',
    'hashedFileName': '/ko/ko-item-kinds.60c3631ed6c7d11d570a0ccdf6a2a9f52147df66.json'
  },
  'koItemSearchCategories': {
    'fileName': '/ko/ko-item-search-categories.json',
    'hashedFileName': '/ko/ko-item-search-categories.f20ebd5780981a82bc50a4fb09752d3f2750a258.json'
  },
  'koItemUiCategories': {
    'fileName': '/ko/ko-item-ui-categories.json',
    'hashedFileName': '/ko/ko-item-ui-categories.370671233071e6dd3ebce07d657cc927a96a7913.json'
  },
  'koItems': {
    'fileName': '/ko/ko-items.json',
    'hashedFileName': '/ko/ko-items.e9f19c5344dfea77f578ba2dd70b2ee808160c0f.json'
  },
  'koJobAbbr': {
    'fileName': '/ko/ko-job-abbr.json',
    'hashedFileName': '/ko/ko-job-abbr.328578fce4885234a099a3c4aa1bd7a87ca2d037.json'
  },
  'koJobCategories': {
    'fileName': '/ko/ko-job-categories.json',
    'hashedFileName': '/ko/ko-job-categories.68bbe413fdde1542a3df8870777411ab071bcc07.json'
  },
  'koJobName': {
    'fileName': '/ko/ko-job-name.json',
    'hashedFileName': '/ko/ko-job-name.e528dd236d9f66b784ad4dd204fe51bd539957c5.json'
  },
  'koJournalGenre': {
    'fileName': '/ko/ko-journal-genre.json',
    'hashedFileName': '/ko/ko-journal-genre.147b70e95f491f759b52231c47c7c25acf2140fe.json'
  },
  'koLeveDescriptions': {
    'fileName': '/ko/ko-leve-descriptions.json',
    'hashedFileName': '/ko/ko-leve-descriptions.82c433a0142bd6dd08ebf9a995bcbd57765d4e81.json'
  },
  'koLeves': {
    'fileName': '/ko/ko-leves.json',
    'hashedFileName': '/ko/ko-leves.0c7a32dd1b04a03e7813183d12c771c8e0ffeb19.json'
  },
  'koMaps': {
    'fileName': '/ko/ko-maps.json',
    'hashedFileName': '/ko/ko-maps.4e5336517d18e7e921881a5cbe497a45f7409404.json'
  },
  'koMobs': {
    'fileName': '/ko/ko-mobs.json',
    'hashedFileName': '/ko/ko-mobs.2621cbb5d25220cb9c258939cfe219ded8ac7408.json'
  },
  'koNotebookDivisionCategory': {
    'fileName': '/ko/ko-notebook-division-category.json',
    'hashedFileName': '/ko/ko-notebook-division-category.6434a569c69f4d682b96fd6de37a130e4ce29963.json'
  },
  'koNotebookDivision': {
    'fileName': '/ko/ko-notebook-division.json',
    'hashedFileName': '/ko/ko-notebook-division.c35768c0379e057135fa363aec42ad9cb0eb1ce1.json'
  },
  'koNpcTitles': {
    'fileName': '/ko/ko-npc-titles.json',
    'hashedFileName': '/ko/ko-npc-titles.5e47f556623cb650ad02b1dfdbbad43bc8370675.json'
  },
  'koNpcs': {
    'fileName': '/ko/ko-npcs.json',
    'hashedFileName': '/ko/ko-npcs.d8827fe34e63ccb13a7b125d304a92cad5a92871.json'
  },
  'koPatchs': {
    'fileName': '/ko/ko-patchs.json',
    'hashedFileName': '/ko/ko-patchs.1cf93ff2e94aa2c7571cb561887497dc366ef167.json'
  },
  'koPlaces': {
    'fileName': '/ko/ko-places.json',
    'hashedFileName': '/ko/ko-places.8ae12a406af4d59189164feb95494d6a67c16e79.json'
  },
  'koQuestDescriptions': {
    'fileName': '/ko/ko-quest-descriptions.json',
    'hashedFileName': '/ko/ko-quest-descriptions.9fff2cac3a3fc85204f8c9fced01489643c298dd.json'
  },
  'koQuests': {
    'fileName': '/ko/ko-quests.json',
    'hashedFileName': '/ko/ko-quests.f4b031df77f59dd7e7477c076fa86468157512dd.json'
  },
  'koRaces': {
    'fileName': '/ko/ko-races.json',
    'hashedFileName': '/ko/ko-races.5c31e1be1e726e9c63cf0271afeb37e79dc36e08.json'
  },
  'koRecipes': {
    'fileName': '/ko/ko-recipes.json',
    'hashedFileName': '/ko/ko-recipes.9da6619915551bc6f74ede336c4cc8bf0bdd40cc.json'
  },
  'koShops': {
    'fileName': '/ko/ko-shops.json',
    'hashedFileName': '/ko/ko-shops.fe440bc0b155cf1c1971774befd89184adcd9bdc.json'
  },
  'koStatusDescriptions': {
    'fileName': '/ko/ko-status-descriptions.json',
    'hashedFileName': '/ko/ko-status-descriptions.6d9e8d8b1d349711b0d899d939bb4f6177f6b2f6.json'
  },
  'koStatuses': {
    'fileName': '/ko/ko-statuses.json',
    'hashedFileName': '/ko/ko-statuses.0beaa0e37ba9bcb948823fae83e746c2a7a4d07a.json'
  },
  'koTraitDescriptions': {
    'fileName': '/ko/ko-trait-descriptions.json',
    'hashedFileName': '/ko/ko-trait-descriptions.0f4f06bbf4bca721dc374bc88080a992ea7d775c.json'
  },
  'koTraits': {
    'fileName': '/ko/ko-traits.json',
    'hashedFileName': '/ko/ko-traits.73b091c4c426c5d11558b20bb745c7425db1ffbf.json'
  },
  'koTribes': {
    'fileName': '/ko/ko-tribes.json',
    'hashedFileName': '/ko/ko-tribes.de9ec7b51df29ea051e0dba47df420213cf60eb0.json'
  },
  'koTripleTriadRuleDescriptions': {
    'fileName': '/ko/ko-triple-triad-rule-descriptions.json',
    'hashedFileName': '/ko/ko-triple-triad-rule-descriptions.f7327eca1078d77daeda4468cf72ae555c933544.json'
  },
  'koTripleTriadRules': {
    'fileName': '/ko/ko-triple-triad-rules.json',
    'hashedFileName': '/ko/ko-triple-triad-rules.7b04fcf02a51feb6db1bbcefa746794825212773.json'
  },
  'koVentures': {
    'fileName': '/ko/ko-ventures.json',
    'hashedFileName': '/ko/ko-ventures.59172dc4df761ace5fe9337d82339dc3e8e102da.json'
  },
  'koWeathers': {
    'fileName': '/ko/ko-weathers.json',
    'hashedFileName': '/ko/ko-weathers.0a89f1c1d4f4ac65460bb563ec4499e338b2403e.json'
  },
  'zhAchievementDescriptions': {
    'fileName': '/zh/zh-achievement-descriptions.json',
    'hashedFileName': '/zh/zh-achievement-descriptions.6659fc066042d1d8a505c0d4c5fb89fa72c4b4a5.json'
  },
  'zhAchievements': {
    'fileName': '/zh/zh-achievements.json',
    'hashedFileName': '/zh/zh-achievements.a9cf8c6687f9c9062d869d09e21e6d5fc9dbfbb4.json'
  },
  'zhActionCategories': {
    'fileName': '/zh/zh-action-categories.json',
    'hashedFileName': '/zh/zh-action-categories.8b6fcd17f87a5af8952fa97d25eb75baf93c16ab.json'
  },
  'zhActionDescriptions': {
    'fileName': '/zh/zh-action-descriptions.json',
    'hashedFileName': '/zh/zh-action-descriptions.1af7fbd170f0396c12e0e2231844c572fd01495f.json'
  },
  'zhActions': {
    'fileName': '/zh/zh-actions.json',
    'hashedFileName': '/zh/zh-actions.44a2eeaaaf8b50a077223d5138ffaeeed01e7d01.json'
  },
  'zhBaseParams': {
    'fileName': '/zh/zh-base-params.json',
    'hashedFileName': '/zh/zh-base-params.ca67a22d695aeff014702fd52296aca9c9961a84.json'
  },
  'zhBeastReputationRanks': {
    'fileName': '/zh/zh-beast-reputation-ranks.json',
    'hashedFileName': '/zh/zh-beast-reputation-ranks.6aeccc710fc85148b04d9c5ae9c24e72d2cb2dde.json'
  },
  'zhContentTypes': {
    'fileName': '/zh/zh-content-types.json',
    'hashedFileName': '/zh/zh-content-types.bb3481d5ce5fc7e722da86a21ead71f444976714.json'
  },
  'zhCraftActions': {
    'fileName': '/zh/zh-craft-actions.json',
    'hashedFileName': '/zh/zh-craft-actions.19c33492d322fc67eac0b90b7ed8e26a5bb2efe3.json'
  },
  'zhCraftDescriptions': {
    'fileName': '/zh/zh-craft-descriptions.json',
    'hashedFileName': '/zh/zh-craft-descriptions.9c1a4599f5f35782ec0b65a08d9d8c4f4c39bd5f.json'
  },
  'zhEventItems': {
    'fileName': '/zh/zh-event-items.json',
    'hashedFileName': '/zh/zh-event-items.91f9d119c5abf43cb33822d6a85d85a17f7889b6.json'
  },
  'zhExVersions': {
    'fileName': '/zh/zh-ex-versions.json',
    'hashedFileName': '/zh/zh-ex-versions.53d79fee046c4d44ace88b2b783506161dd8c34b.json'
  },
  'zhFates': {
    'fileName': '/zh/zh-fates.json',
    'hashedFileName': '/zh/zh-fates.6da5beea5476035211339e4e5b025755f462d948.json'
  },
  'zhFreeCompanyActions': {
    'fileName': '/zh/zh-free-company-actions.json',
    'hashedFileName': '/zh/zh-free-company-actions.bee80b7209459693b3f7c338716db23c202cbd55.json'
  },
  'zhGatheringBonuses': {
    'fileName': '/zh/zh-gathering-bonuses.json',
    'hashedFileName': '/zh/zh-gathering-bonuses.eb5ca41e72090d6da55748bc32f607caa8b14e8d.json'
  },
  'zhGatheringTypes': {
    'fileName': '/zh/zh-gathering-types.json',
    'hashedFileName': '/zh/zh-gathering-types.62cd5f228df42275880adbab87a012320bbd0a64.json'
  },
  'zhInstanceDescriptions': {
    'fileName': '/zh/zh-instance-descriptions.json',
    'hashedFileName': '/zh/zh-instance-descriptions.62e6b5e15d4e1f39aba6f498f3a509c0777afa71.json'
  },
  'zhInstances': {
    'fileName': '/zh/zh-instances.json',
    'hashedFileName': '/zh/zh-instances.677e5b00d534365799a58e39bcaa78c341f232a5.json'
  },
  'zhItemDescriptions': {
    'fileName': '/zh/zh-item-descriptions.json',
    'hashedFileName': '/zh/zh-item-descriptions.6ef070550c54aecbb8e5bb71640aada022b57cdb.json'
  },
  'zhItemKinds': {
    'fileName': '/zh/zh-item-kinds.json',
    'hashedFileName': '/zh/zh-item-kinds.2a8282489136bea259010904b4b1bff4ba0a6c74.json'
  },
  'zhItemSearchCategories': {
    'fileName': '/zh/zh-item-search-categories.json',
    'hashedFileName': '/zh/zh-item-search-categories.98275155051877bb854280b6110394231431fff0.json'
  },
  'zhItemUiCategories': {
    'fileName': '/zh/zh-item-ui-categories.json',
    'hashedFileName': '/zh/zh-item-ui-categories.d175023b1c59ce0af7e34dc6c87a79c8b63539ab.json'
  },
  'zhItems': {
    'fileName': '/zh/zh-items.json',
    'hashedFileName': '/zh/zh-items.6d238114fa3a77a46a1b5a902d505e16d1736b19.json'
  },
  'zhJobAbbr': {
    'fileName': '/zh/zh-job-abbr.json',
    'hashedFileName': '/zh/zh-job-abbr.f20fa803338f3f5db97cf8732d312f6e34f12451.json'
  },
  'zhJobCategories': {
    'fileName': '/zh/zh-job-categories.json',
    'hashedFileName': '/zh/zh-job-categories.2ae2daeeb2c9d22b744d49d9c5926e84848ea1ae.json'
  },
  'zhJobName': {
    'fileName': '/zh/zh-job-name.json',
    'hashedFileName': '/zh/zh-job-name.f20fa803338f3f5db97cf8732d312f6e34f12451.json'
  },
  'zhJournalGenre': {
    'fileName': '/zh/zh-journal-genre.json',
    'hashedFileName': '/zh/zh-journal-genre.582ababa51aa6f0c0319b2a579580374e084fe26.json'
  },
  'zhLeveDescriptions': {
    'fileName': '/zh/zh-leve-descriptions.json',
    'hashedFileName': '/zh/zh-leve-descriptions.4f1dfe146727b551d9258568efe86f9a7735c759.json'
  },
  'zhLeves': {
    'fileName': '/zh/zh-leves.json',
    'hashedFileName': '/zh/zh-leves.b230cc3cea7259486c99177c1ea271d8b9f0f7f0.json'
  },
  'zhMaps': {
    'fileName': '/zh/zh-maps.json',
    'hashedFileName': '/zh/zh-maps.e8d9c4da6457139a3c9016a7385c5659be7e93c4.json'
  },
  'zhMobs': {
    'fileName': '/zh/zh-mobs.json',
    'hashedFileName': '/zh/zh-mobs.a8ea7ebb112c8a4c8cec8554ad73e4b0a8fd06b0.json'
  },
  'zhNotebookDivisionCategory': {
    'fileName': '/zh/zh-notebook-division-category.json',
    'hashedFileName': '/zh/zh-notebook-division-category.45a6c6d3aab82b745cd316891dbed18c92344073.json'
  },
  'zhNotebookDivision': {
    'fileName': '/zh/zh-notebook-division.json',
    'hashedFileName': '/zh/zh-notebook-division.3f09fe8c09c6c60c2f72339689705785b178abab.json'
  },
  'zhNpcTitles': {
    'fileName': '/zh/zh-npc-titles.json',
    'hashedFileName': '/zh/zh-npc-titles.330e3c559d7931ad0dfc89c739ad50dd7d77dd10.json'
  },
  'zhNpcs': {
    'fileName': '/zh/zh-npcs.json',
    'hashedFileName': '/zh/zh-npcs.409fe3009011699b1bfa2d595b01461beebfc3f9.json'
  },
  'zhPatchs': {
    'fileName': '/zh/zh-patchs.json',
    'hashedFileName': '/zh/zh-patchs.1b0a3bfa74dda34c66e385d95f8fe47f01e7c0b5.json'
  },
  'zhPlaces': {
    'fileName': '/zh/zh-places.json',
    'hashedFileName': '/zh/zh-places.83b46aca87771b77e5a97d3dcd4c695d76514bd7.json'
  },
  'zhQuestDescriptions': {
    'fileName': '/zh/zh-quest-descriptions.json',
    'hashedFileName': '/zh/zh-quest-descriptions.f57ab52474a325ad4b07581b65f31922ed1e68d7.json'
  },
  'zhQuests': {
    'fileName': '/zh/zh-quests.json',
    'hashedFileName': '/zh/zh-quests.027c347a1f18318407c90ddedef577088eaf81a8.json'
  },
  'zhRaces': {
    'fileName': '/zh/zh-races.json',
    'hashedFileName': '/zh/zh-races.209ab6dd923346904cd21c5accca31fb9f3e5fa4.json'
  },
  'zhRecipes': {
    'fileName': '/zh/zh-recipes.json',
    'hashedFileName': '/zh/zh-recipes.8ed5775df9ed34798f552b521477a29a110a71a5.json'
  },
  'zhShops': {
    'fileName': '/zh/zh-shops.json',
    'hashedFileName': '/zh/zh-shops.462763062d4cd95c37c3ebe334b876defaf2614e.json'
  },
  'zhStatusDescriptions': {
    'fileName': '/zh/zh-status-descriptions.json',
    'hashedFileName': '/zh/zh-status-descriptions.3083458b486433aa190d4abb7242974fb9d1dd6b.json'
  },
  'zhStatuses': {
    'fileName': '/zh/zh-statuses.json',
    'hashedFileName': '/zh/zh-statuses.fd7e41a9088c03114a7293b5820a72f3ec8b8978.json'
  },
  'zhTraitDescriptions': {
    'fileName': '/zh/zh-trait-descriptions.json',
    'hashedFileName': '/zh/zh-trait-descriptions.87dc4ef6684c1f11d2ab89abc099487105141372.json'
  },
  'zhTraits': {
    'fileName': '/zh/zh-traits.json',
    'hashedFileName': '/zh/zh-traits.9c901764f14a6c9055d8e6c7840e5f40b015f34c.json'
  },
  'zhTribes': {
    'fileName': '/zh/zh-tribes.json',
    'hashedFileName': '/zh/zh-tribes.2e4bb65084c0643c8c539e6e3f8f41e7cef44b01.json'
  },
  'zhTripleTriadRuleDescriptions': {
    'fileName': '/zh/zh-triple-triad-rule-descriptions.json',
    'hashedFileName': '/zh/zh-triple-triad-rule-descriptions.777906420c74b8a43b3c9ee8e0f4ce6057985efb.json'
  },
  'zhTripleTriadRules': {
    'fileName': '/zh/zh-triple-triad-rules.json',
    'hashedFileName': '/zh/zh-triple-triad-rules.e7b86ac8dd4361c6a8529020c04f03beb377a407.json'
  },
  'zhVentures': {
    'fileName': '/zh/zh-ventures.json',
    'hashedFileName': '/zh/zh-ventures.a7cb62a874a6b5fdf040e3f10db8ba31c683f38b.json'
  },
  'zhWeathers': {
    'fileName': '/zh/zh-weathers.json',
    'hashedFileName': '/zh/zh-weathers.a41a8dd84011418ddffc8cad27a1090e8f9fbcd9.json'
  }
};