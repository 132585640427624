import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherIcon',
})
export class WeatherIconPipe implements PipeTransform {
  transform(id: number): string | undefined {
    if (isNaN(+id)) return undefined;
    const icon = this.getWeatherIcon(id);
    return `./assets/icons/weather/0602${icon.toString().padStart(2, '0')}.png`;
  }

  private getWeatherIcon(id: number): number {
    switch (id) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        return id;
      case 17:
        return 18;
      case 18:
        return 17;
      case 19:
        return 51;
      case 20:
        return 55;
      case 21:
        return 3;
      case 22:
        return 59;
      case 23:
        return 58;
      case 24:
        return 58;
      case 25:
        return 57;
      case 26:
        return 52;
      case 27:
        return 56;
      case 28:
        return 53;
      case 29:
        return 54;
      case 30:
        return 2;
      case 31:
        return 2;
      case 32:
        return 2;
      case 33:
        return 2;
      case 34:
        return 2;
      case 35:
        return 60;
      case 36:
        return 61;
      case 37:
        return 61;
      case 38:
        return 61;
      case 39:
        return 61;
      case 40:
        return 62;
      case 41:
        return 62;
      case 42:
        return 62;
      case 43:
        return 62;
      case 44:
        return 64;
      case 45:
        return 64;
      case 46:
        return 64;
      case 47:
        return 64;
      case 48:
        return 64;
      case 49:
        return 19;
      case 50:
        return 20;
      case 51:
        return 63;
      case 52:
        return 2;
      case 53:
        return 26;
      case 54:
        return 27;
      case 55:
        return 26;
      case 56:
        return 64;
      case 57:
        return 9;
      case 58:
        return 9;
      case 61:
        return 65;
      case 62:
        return 7;
      case 63:
        return 2;
      case 64:
        return 7;
      case 65:
        return 2;
      case 66:
        return 66;
      case 67:
        return 66;
      case 68:
        return 2;
      case 69:
        return 67;
      case 70:
        return 67;
      case 71:
        return 68;
      case 72:
        return 68;
      case 73:
        return 68;
      case 74:
        return 69;
      case 75:
        return 69;
      case 76:
        return 69;
      case 77:
        return 74;
      case 78:
        return 74;
      case 79:
        return 74;
      case 80:
        return 71;
      case 81:
        return 71;
      case 82:
        return 70;
      case 83:
        return 70;
      case 84:
        return 72;
      case 85:
        return 72;
      case 86:
        return 71;
      case 87:
        return 73;
      case 88:
        return 9;
      case 89:
        return 74;
      case 90:
        return 2;
      case 91:
        return 1;
      case 92:
        return 75;
      case 93:
        return 75;
      case 94:
        return 75;
      case 95:
        return 77;
      case 96:
        return 75;
      case 97:
        return 76;
      case 98:
        return 76;
      case 99:
        return 76;
      case 100:
        return 76;
      case 101:
        return 2;
      case 102:
        return 78;
      case 103:
        return 78;
      case 104:
        return 78;
      case 105:
        return 2;
      case 106:
        return 2;
      case 107:
        return 2;
      case 108:
        return 2;
      case 109:
        return 79;
      case 110:
        return 80;
      case 111:
        return 81;
      case 112:
        return 1;
    }
  }
}
