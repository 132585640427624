<p [innerHTML]="'SUPPORT_US.Popup_introduction' | translate"></p>
<ul>
  <li>{{'SUPPORT_US.PATREON_BENEFITS.No_ads' | translate }}</li>
  <li>{{'SUPPORT_US.PATREON_BENEFITS.Discord_rank' | translate: { price: 1 } }}</li>
  <li>{{'SUPPORT_US.PATREON_BENEFITS.Profile_badge' | translate: { price: 1 } }}</li>
  <li>{{'SUPPORT_US.PATREON_BENEFITS.Custom_theme' | translate: { price: 1 } }}</li>
  <li>{{'SUPPORT_US.PATREON_BENEFITS.Loading_screen_message' | translate: { price: 10 } }}</li>
</ul>
<nz-divider></nz-divider>
<p>{{'SUPPORT_US.Already_supporting' | translate}}</p>

