<p>{{'TUTORIAL.POPUP.Explanation' | translate}}</p>
<p>{{'TUTORIAL.POPUP.Question' | translate}}</p>
<i>{{'TUTORIAL.POPUP.Can_be_changed_in_settings' | translate}}</i>
<div fxLayout="row" fxLayoutGap="5px">
  <div>
    <button nz-button nzType="primary" (click)="close(true)">{{'Yes' | translate}}</button>
  </div>
  <div>
    <button nz-button (click)="close(false)">{{'No' | translate}}</button>
  </div>
</div>
