<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <img src="{{gif}}" alt="">
  <div class="brought-to-you">
    Brought to you by {{patron}}
  </div>
  <div>{{'LOADING_SCREEN.Loading_data' | translate}}</div>
  <a href="https://www.patreon.com/bePatron?u=702160" class="patreon-widget" target="_blank">
    <img src="./assets/become_a_patron_button@2x.png" alt="Become a patron">
  </a>
</div>
