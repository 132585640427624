export const monsterDrops = {
  "8506": [
    27764
  ],
  "8507": [
    27763
  ],
  "8511": [
    27732
  ],
  "8525": [
    27850
  ],
  "8533": [
    27798
  ],
  "8552": [
    27852,
    27756
  ],
  "8553": [
    27852,
    27756
  ],
  "8563": [
    27735
  ],
  "8577": [
    27733
  ],
  "8582": [
    27851,
    27797
  ],
  "8600": [
    27774
  ],
  "8604": [
    27734
  ],
  "8613": [
    27773
  ],
  "8621": [
    27799
  ],
  "8628": [
    27800
  ],
  "8630": [
    27736
  ]
};