export const patchNotes = `### Bug Fixes

* **inventory-view:** fixed double tooltip on item icons.
* **notifications:** fixed an issue with notifications affecting autofill system.


### Features

* **db:** new data sources for various items.
* **log-tracker:** "hide completed" is now persisted inside settings.`;
