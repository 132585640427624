<div>
  <nz-form-item>
    <nz-form-control [nzExtra]="'GEARSETS.Please_input_lodestone_id' | translate">
      <input [(ngModel)]="lodestoneId" [placeholder]="'GEARSETS.Paste_lodestone_id' | translate" nz-input
             type="text">
    </nz-form-control>
  </nz-form-item>

  <button (click)="submit()" [disabled]="!lodestoneId" nz-button
          nzType="primary">{{'Import' | translate}}</button>
</div>
