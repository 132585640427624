<ng-container *ngIf="loaded$ | async; else loading">
  <div *ngIf="!overlayMode" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
    <nz-switch [(ngModel)]="settings.alarmsMuted"></nz-switch>
    <div>{{'Mute_alarms' | translate}} </div>
  </div>
  <div *ngIf="alarms$ | async as alarms" [class.sidebar]="!overlayMode" class="alarms-container">
    <div *ngFor="let row of alarms; trackBy: trackByAlarm; let last = last">
      <div [class.played]="row.played && !row.spawned" [class.spawned]="row.spawned" class="alarm-row" fxLayout="row"
           fxLayoutGap="5px">
        <div class="ephemeral">
          <i *ngIf="row.alarm.ephemeral" [nzTooltipTitle]="'GATHERING_LOCATIONS.Ephemeral_node' | translate" nz-icon nz-tooltip
             nzType="clock-circle"></i>
        </div>
        <div class="item-icon">
          <app-item-icon [disableClick]="overlayMode" [icon]="row.alarm.itemId | lazyIcon"
                         [itemId]="row.alarm.itemId" [itemName]="row.alarm.itemId | itemName: row.alarm | i18n" [width]="32"></app-item-icon>
          <img alt="" class="node-type" src="{{row.alarm.type | nodeTypeIcon: true}}">
        </div>
        <div class="center-content">
          <span *ngIf="row.alarm.itemId; else customName" class="item-name">
            <i *ngIf="row.groupNames" [nzTooltipTitle]="row.groupNames" class="folder-icon" nz-icon
               nz-tooltip nzType="folder-open"></i>
            {{row.alarm.itemId | itemName: row.alarm | i18n}}
            <i *ngIf="row.alarm.note" [nzTooltipTitle]="row.alarm.note" class="note-icon" nz-icon
               nz-tooltip nzType="edit"></i>
          </span>
          <ng-template #customName>
            <i *ngIf="row.groupNames" [nzTooltipTitle]="row.groupNames" class="folder-icon" nz-icon
               nz-tooltip nzType="folder-open"></i>
            <span class="item-name">{{row.alarm.name}}</span>
          </ng-template>
          <div fxLayout="row wrap" fxLayoutGap="5px">
            <div class="timer">{{row.remainingTime | timer}}</div>
            <div
              class="map">{{row.alarm.aetheryte?.nameid |  placeName | i18n}}</div>
            <div class="coords">X:{{row.alarm.coords.x}} - Y: {{row.alarm.coords.y}}</div>
          </div>
          <div *ngIf="row.alarm.baits">
            <app-fishing-bait [baits]="row.alarm.baits" flex="row wrap" hideNames="true" iconWidth="24"></app-fishing-bait>
          </div>
        </div>
        <img *ngIf="row.alarm.fishEyes" [src]="762 | statusIcon | xivapiIcon" alt="" class="fish-eyes" nz-tooltip
             [nzTooltipTitle]="'GATHERING_LOCATIONS.Fish_eyes' | translate">
        <div *ngIf="!overlayMode" class="map-button">
          <button (click)="openMap(row.alarm)" nz-button nzShape="circle" nzSize="small" nzType="primary">
            <i nz-icon nzType="environment-o"></i>
          </button>
        </div>
        <div class="top-right-icons" fxLayout="row" fxLayoutGap="5px">
          <div (click)="hideAlarm(row.alarm)" class="hide-icon">
            <i nz-icon nzType="eye-invisible" nzTheme="outline"></i>
          </div>
          <div (nzOnConfirm)="deleteAlarm(row.alarm)" nz-popconfirm [nzPopconfirmTitle]="'Confirmation' | translate" class="delete-icon">
            <i nz-icon nzType="close"></i>
          </div>
        </div>
      </div>
      <nz-divider *ngIf="!last"></nz-divider>
    </div>
    <app-fullpage-message *ngIf="alarms.length === 0">
      {{'ALARMS.No_alarm' | translate}}
    </app-fullpage-message>
  </div>
</ng-container>
<ng-template #loading>
  <app-page-loader></app-page-loader>
</ng-template>
