<nz-alert *ngIf="warning" [nzMessage]="warning | translate" nzShowIcon nzType="warning">
</nz-alert>
<nz-input-group [nzSuffix]="suffixIconSearch">
  <input #searchInput (keyup)="query$.next(searchInput.value)" autofocus nz-input
         placeholder="{{'SIMULATOR.Recipe_picker_placeholder' | translate}}" type="text">
</nz-input-group>
<ng-template #suffixIconSearch>
  <i nz-icon nzType="search"></i>
</ng-template>
<nz-list [nzDataSource]="results$ | async" [nzLoading]="loading" [nzRenderItem]="recipeTemplate">
  <ng-template #recipeTemplate let-recipe>
    <nz-list-item [nzContent]="content">
      <nz-list-item-meta [nzAvatar]="avatar"
                         [nzDescription]="description"
                         [nzTitle]="recipe.itemId | itemName | i18n">
        <ng-template #avatar>
          <app-item-icon [icon]="recipe.icon" [itemId]="recipe.itemId" [width]="32"></app-item-icon>
        </ng-template>
        <ng-template #description>
          <img alt="getJob(item.job)?.abbreviation"
               class="crafted-by" src="https://www.garlandtools.org/db/images/{{getJob(recipe.job)?.abbreviation}}.png">
          {{recipe.lvl}}
          {{getStars(recipe.stars)}}
        </ng-template>
      </nz-list-item-meta>
      <ng-template #content>
        <a (click)="close()" [nzShape]="'circle'" [nzType]="'primary'" nz-button
                *ngIf="!pickRotation"
                routerLink="/simulator/{{recipe.itemId}}/{{recipe.recipeId}}/{{rotationId}}" [queryParams]="statsStr?{stats: statsStr}:null">
          <i nz-icon nzType="check"></i>
        </a>

        <button (click)="openRotationPicker(recipe)" [nzShape]="'circle'" [nzType]="'primary'" nz-button
                *ngIf="pickRotation">
          <i nz-icon nzType="check"></i>
        </button>
      </ng-template>
    </nz-list-item>
  </ng-template>
</nz-list>
<div *ngIf="showCustom">
  <nz-divider [nzText]="'Or' | translate"></nz-divider>
  <a (click)="close()" class="new-list-button" nz-button nzBlock
     routerLink="/simulator/custom/{{rotationId}}" [queryParams]="statsStr?{stats: statsStr}:null">
    {{'SIMULATOR.Create_a_custom_rotation' | translate}}
  </a>
</div>
