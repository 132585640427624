<nz-tabset [nzSelectedIndex]="selectedTab" class="tabset" nzTabPosition="left">
  <nz-tab [nzTitle]="'SETTINGS.General' | translate">
    <div fxLayout="column" fxLayoutGap="10px">
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'COMMON.Language' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select (ngModelChange)="setLanguage($event)" [ngModel]="translate.currentLang">
            <nz-option *ngFor="let locale of availableLanguages" [nzLabel]="locale | uppercase"
                       [nzValue]="locale"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Region' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="validating" [nzValidatingTip]="'SETTINGS.Region_explain' | translate">
          <nz-select [(ngModel)]="settings.region">
            <nz-option *ngFor="let region of availableRegions" [nzLabel]="'SETTINGS.REGIONS.' + region | translate"
                       [nzValue]="region"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Theme' | translate"></nz-divider>
      </div>
      <nz-form-item *ngIf="sidebarItems$ | async as sidebarItems">
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Favorite_features' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="validating" [nzValidatingTip]="'SETTINGS.Favorite_features_explain' | translate">
          <nz-select [(ngModel)]="sidebarFavorites"
                     (ngModelChange)="settings.sidebarFavorites = sidebarFavorites"
                     nzShowSearch nzMode="multiple">
            <nz-option *ngFor="let item of sidebarItems" [nzLabel]="item.name | translate"
                       [nzValue]="item.link"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <ng-container *ngIf="user$ | async as user">
        <nz-form-item>
          <nz-form-label [nzSpan]="5">{{'SETTINGS.Theme' | translate}}</nz-form-label>
          <nz-form-control [nzSpan]="12">
            <nz-select (ngModelChange)="setTheme($event)" [ngModel]="settings.theme?.name">
              <nz-option *ngFor="let theme of availableThemes" [nzLabel]="theme?.name"
                         [nzValue]="theme?.name"></nz-option>
              <nz-option *ngIf="user.patron || user.admin" [nzLabel]="'CUSTOM'"
                         [nzValue]="'CUSTOM'"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="(user.patron || user.admin) && settings.theme?.name === 'CUSTOM'" class="empty-label">
          <nz-form-label [nzSpan]="5">{{'SETTINGS.Theme' | translate}}</nz-form-label>
          <nz-form-control [nzSpan]="12">
            <div class="custom-theme-editor" fxLayout="column" fxLayoutGap="5px">
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Background' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.background"
                       [style.background]="customTheme.background" class="color-picker"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Primary' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.primary"
                       [style.background]="customTheme.primary" class="color-picker"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Highlight' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.highlight"
                       [style.background]="customTheme.highlight" class="color-picker"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Text' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.text"
                       [style.background]="customTheme.text" class="color-picker"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Topbar' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.topbar"
                       [style.background]="customTheme.topbar" class="color-picker"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Sidebar_trigger' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.trigger"
                       [style.background]="customTheme.trigger" class="color-picker"></div>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="5px">
                <div fxLayout="1 1 auto">{{'SETTINGS.COLORS.Sidebar_trigger_hover' | translate}}</div>
                <div class="color-container color-picker">
                  <div (colorPickerChange)="saveCustomTheme()" [(colorPicker)]="customTheme.triggerHover"
                       [style.background]="customTheme.triggerHover" class="color-picker"></div>
                </div>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.autoShowPatchNotes"
                 nz-checkbox>{{'SETTINGS.Show_patch_notes_on_update' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.tutorialEnabled"
                 nz-checkbox>{{'SETTINGS.Enable_tutorials' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.noPanelBorders"
                 nz-checkbox>{{'SETTINGS.no_panel_borders' | translate}}</label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Db_comments_position' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select [(ngModel)]="settings.dbCommentsPosition">
            <nz-option [nzLabel]="'COMMON.Top' | translate" [nzValue]="'TOP'"></nz-option>
            <nz-option [nzLabel]="'COMMON.Bottom' | translate" [nzValue]="'BOTTOM'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Search' | translate"></nz-divider>
      </div>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <button (click)="clearSearchHistory()" class="clear-search" fxFlexAlign="center center" nz-button>
            {{'SETTINGS.Clear_search_history' | translate}}
          </button>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.disableSearchHistory"
                 nz-checkbox>{{'SETTINGS.Disable_search_history' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.disableSearchDebounce"
                 nz-checkbox>{{'SETTINGS.Disable_search_debounce' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.showAllAlarms"
                 nz-checkbox>{{'SETTINGS.Show_all_alarms' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Lists' | translate"></nz-divider>
      </div>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Scrolling_behavior' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="validating" [nzValidatingTip]="'SETTINGS.Scrolling_behavior_explain' | translate">
          <nz-select [(ngModel)]="settings.listScrollingMode">
            <nz-option [nzLabel]="'LIST.SCROLLING.Default' | translate" nzValue="default"></nz-option>
            <nz-option [nzLabel]="'LIST.SCROLLING.Only_large' | translate" nzValue="large"></nz-option>
            <nz-option [nzLabel]="'LIST.SCROLLING.Never' | translate" nzValue="never"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Amount_display_mode' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-switch
            [(ngModel)]="settings.displayRemaining"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
          ></nz-switch>
          <ng-template #checkedTemplate>{{'SETTINGS.Amount_display_mode_remaining' | translate}}</ng-template>
          <ng-template #unCheckedTemplate>{{'SETTINGS.Amount_display_mode_total' | translate}}</ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'PERMISSIONS.Default_permission_level' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select [(ngModel)]="settings.defaultPermissionLevel">
            <nz-option [nzLabel]="'NONE' | translate" [nzValue]="1"></nz-option>
            <nz-option [nzLabel]="'READ' | translate" [nzValue]="10"></nz-option>
            <nz-option [nzLabel]="'PARTICIPATE' | translate" [nzValue]="20"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.autoMarkAsCompleted"
                 nz-checkbox>{{'SETTINGS.Auto_mark_as_done_in_log' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Maximum_vendor_price' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzExtra]="'SETTINGS.Maximum_vendor_price_explain' | translate">
          <nz-input-number [(ngModel)]="settings.maximumVendorPrice"></nz-input-number>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="loggedIn$ | async" class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.showCopyOnOwnList"
                 nz-checkbox>{{'SETTINGS.Show_copy_list_own_list' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="loggedIn$ | async" class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.itemTagsEnabled"
                 nz-checkbox>{{'SETTINGS.Enable_item_tags' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Alarms' | translate"></nz-divider>
      </div>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.alarmPanelsCollapsedByDefault"
                 nz-checkbox>{{'SETTINGS.Alarm_panels_collapsed_by_default' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.alarmGroupsBeforeNoGroup"
                 nz-checkbox>{{'SETTINGS.Alarm_groups_before_no_group' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Other' | translate"></nz-divider>
      </div>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.disableCrossWorld"
                 nz-checkbox>{{'SETTINGS.Disable_cross_world_prices' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.alwaysHQLeves"
                 nz-checkbox>{{'SETTINGS.Always_HQ_leves' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.alwaysAllDeliveries"
                 nz-checkbox>{{'SETTINGS.Always_all_deliveries' | translate}}</label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Preferred_copy_type' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select [(ngModel)]="settings.preferredCopyType">
            <nz-option [nzLabel]="'SETTINGS.COPY_TYPE.Classic' | translate" [nzValue]="'classic'"></nz-option>
            <nz-option [nzLabel]="'SETTINGS.COPY_TYPE.Isearch' | translate" [nzValue]="'isearch'"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="trackItemsOnSale" nz-checkbox>
            {{'SETTINGS.Track_items_on_sale' | translate}}
          </label>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item *ngIf="!platform.isDesktop()" class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.autoOpenInDesktop"
                 nz-checkbox>{{'SETTINGS.Auto_open_in_desktop' | translate}}</label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="empty-label">
        <nz-form-label [nzSpan]="5"></nz-form-label>
        <nz-form-control [nzSpan]="12">
          <label [(ngModel)]="settings.disableHQSuggestions"
                 nz-checkbox>{{'SETTINGS.Always_disable_hq_suggestions' | translate}}</label>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Starting_place' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzExtra]="'SETTINGS.Starting_place_explain' | translate">
          <nz-select [(ngModel)]="settings.startingPlace">
            <nz-option *ngFor="let place of startingPlaces" [nzLabel]="place.placenameId | placeName | i18n"
                       [nzValue]="place.id"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Free_aetheryte' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzExtra]="'SETTINGS.Starting_place_explain' | translate">
          <nz-select [(ngModel)]="settings.freeAetheryte"
                     nzAllowClear
                     nzShowSearch
                     style="width: 100%">
            <nz-option *ngFor="let aetheryte of allAetherytes"
                       [nzLabel]="(aetheryte.id | aetheryteName | i18n) || (aetheryte?.nameid | placeName | i18n)"
                       [nzValue]="aetheryte?.nameid"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Favorite_aetherytes' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" [nzExtra]="'SETTINGS.Starting_place_explain' | translate">
          <nz-select
            [(ngModel)]="favoriteAetherytes"
            (ngModelChange)="settings.favoriteAetherytes = favoriteAetherytes"
            [nzMaxMultipleCount]="4"
            nzAllowClear
            nzMode="multiple"
            style="width: 100%">
            <nz-option *ngFor="let aetheryte of allAetherytes"
                       [nzLabel]="(aetheryte.id | aetheryteName | i18n) || (aetheryte?.nameid | placeName | i18n)"
                       [nzValue]="aetheryte?.nameid"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Import_export' | translate"></nz-divider>
      </div>
      <button (click)="downloadSettings()" nz-button nzType="primary">
        <i nz-icon nzType="upload"></i> {{'SETTINGS.Export_settings' | translate}}
      </button>
      <nz-upload nzType="drag" nzAccept=".json"
                 [nzCustomRequest]="handleFile">
        <div class="upload-content">
          <p class="ant-upload-drag-icon">
            <i nz-icon nzType="download"></i>
          </p>
          <p class="ant-upload-text">{{'SETTINGS.Import_settings' | translate}}</p>
        </div>
      </nz-upload>
    </div>
  </nz-tab>
  <nz-tab *ngIf="loggedIn$ | async" [nzTitle]="'SETTINGS.Account' | translate">
    <div class="account-buttons" fxLayout="column" fxLayoutGap="10px">
      <button (nzOnConfirm)="resetPassword()" [nzPopconfirmTitle]="'SETTINGS.Reset_password_warning' | translate" nz-button nz-popconfirm>
        {{'SETTINGS.Reset_password' | translate}}
      </button>
      <button (click)="updateEmail()" nz-button nz-popconfirm>
        {{'SETTINGS.Change_email' | translate}}
      </button>
      <ng-container *ngIf="user$ | async as user">
        <div>UserID: {{user.$key}}</div>
        <button (click)="patreonOauth()" *ngIf="user.patreonToken === undefined; else connectedWithPatreon" nz-button
                nzType="primary">
          {{'SETTINGS.Connect_with_patreon' | translate}}
        </button>
        <ng-template #connectedWithPatreon>
          <div fxLayout="row" fxLayoutAlign="flex-start center">
            <div>
              {{'SETTINGS.Connected_with_patreon' | translate}}
            </div>
            <button nz-button nzShape="circle" nzSize="small" nzDanger
                    nz-tooltip [nzTooltipTitle]="'SETTINGS.Disconnect_patreon' | translate"
                    nz-popconfirm [nzPopconfirmTitle]="'Confirmation' | translate" (nzOnConfirm)="disconnectPatreon(user)">
              <i nz-icon nzType="disconnect" nzTheme="outline"></i>
            </button>
          </div>
        </ng-template>
        <div *ngIf="user.patron || user.admin" fxLayout="column" fxLayoutGap="5px">
          <nz-input-group>
            <nz-form-label>{{'SETTINGS.Nickname' | translate}}</nz-form-label>
            <nz-form-control [nzValidateStatus]="nicknameAvailable === false ? 'error' : null" [nzExtra]="'SETTINGS.Nickname_used' | translate">
              <input #nickname (ngModelChange)="nicknameAvailable = undefined" [ngModel]="user.nickname" nz-input
                     placeholder="Nickname"
                     type="text">
            </nz-form-control>
          </nz-input-group>
          <button (click)="checkNicknameAvailability(nickname.value)"
                  *ngIf="!nicknameAvailable && nickname.value !== user.nickname" nz-button
                  nzBlock>Verify
          </button>
          <button (click)="setNickname(user, nickname.value)"
                  *ngIf="nicknameAvailable && nickname.value !== user.nickname" nz-button
                  nzBlock nzType="primary">Apply
          </button>
        </div>
      </ng-container>
    </div>
  </nz-tab>
  <nz-tab *ngIf="platform.isDesktop()" [nzTitle]="'SETTINGS.Desktop' | translate">
    <div fxLayout="column" fxLayoutGap="5px">
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.General' | translate"></nz-divider>
      </div>
      <div>
        <label [(ngModel)]="alwaysOnTop" (ngModelChange)="alwaysOnTopChange($event)" nz-checkbox>
          {{'SETTINGS.Always_on_top' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="noShortcut" (ngModelChange)="noShortcutChange($event)" nz-checkbox>
          {{'SETTINGS.No_shortcut' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="startMinimized" (ngModelChange)="startMinimizedChange($event)" nz-checkbox>
          {{'SETTINGS.Start_minimized' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.autoDownloadUpdate" nz-checkbox>
          {{'SETTINGS.Auto_download_update' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="alwaysQuit" (ngModelChange)="alwaysQuitChange($event)" nz-checkbox>
          {{'SETTINGS.Always_quit' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.clickthroughOverlay" nz-checkbox>
          {{'SETTINGS.Clickthrough_overlay' | translate}}
        </label>
      </div>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Networking' | translate"></nz-divider>
      </div>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Proxy' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <div nz-row [nzGutter]="8">
            <div nz-col [nzSpan]="6">
              <nz-select [(ngModel)]="proxyType" class="proxy-type" (ngModelChange)="commitProxy()">
                <nz-option nzLabel="None" nzValue=""></nz-option>
                <nz-option nzLabel="HTTP" nzValue="http"></nz-option>
                <nz-option nzLabel="HTTPS" nzValue="https"></nz-option>
                <nz-option nzLabel="Socks v4" nzValue="socks4"></nz-option>
                <nz-option nzLabel="Socks v5" nzValue="socks5"></nz-option>
                <nz-option nzLabel="PAC" nzValue="pac"></nz-option>
                <nz-option nzLabel="Custom" nzValue="custom"></nz-option>
              </nz-select>
            </div>
            <div nz-col [nzSpan]="18">
              <input *ngIf="proxyType !== ''" nz-input [(ngModel)]="proxyValue" (blur)="commitProxy()">
            </div>
          </div>
          <span *ngIf="proxyType !== ''" class="proxy-help" [innerHTML]="('COMMON.Example' | translate) + ': ' + proxyExample"></span>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'SETTINGS.Proxy_bypass' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12" nzValidateStatus="validating"
                         [nzValidatingTip]="('COMMON.Example' | translate) + ': xivapi.com,www.garlandtools.org'">
          <input nz-input [(ngModel)]="proxyBypass" (blur)="ipc.send('proxy-bypass', proxyBypass)">
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Autofill_completion' | translate"></nz-divider>
      </div>
      <nz-form-item>
        <nz-form-label [nzSpan]="5">{{'Sound' | translate}}</nz-form-label>
        <nz-form-control [nzSpan]="12">
          <nz-select (ngModelChange)="setSound($event)" [ngModel]="settings.autofillCompletionSound" class="select-box"
                     id="sound">
            <nz-option *ngFor="let sound of sounds" [nzLabel]="sound" [nzValue]="sound"></nz-option>
          </nz-select>
          <nz-slider (nzOnAfterChange)="setVolume($event/100)" [ngModel]="settings.autofillCompletionVolume * 100"
                     [nzMax]="100" [nzMin]="0"
                     [nzStep]="1"></nz-slider>
        </nz-form-control>
      </nz-form-item>
      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Packet_capture' | translate"></nz-divider>
      </div>
      <nz-card>
        <div fxLayout="column">
          <div>
            <label [(ngModel)]="machinaToggle" (ngModelChange)="machinaToggleChange($event)" nz-checkbox>
              {{'SETTINGS.Enable_packet_capture' | translate}}
            </label>
          </div>
          <div>
            <i>{{'SETTINGS.Packet_capture_info' | translate}}</i>
          </div>
          <div>
            <label [(ngModel)]="rawsock" (ngModelChange)="rawsockChange($event)" [nzDisabled]="!machinaToggle" nz-checkbox>
              {{'SETTINGS.Rawsock_flag' | translate}}
            </label>
          </div>
          <div>
            <i>{{'SETTINGS.Rawsock_details' | translate}}</i>
          </div>
          <div>
            <label [(ngModel)]="settings.enableUniversalisSourcing" [nzDisabled]="!machinaToggle" nz-checkbox>
              {{'SETTINGS.Contribute_to_universalis' | translate}}
            </label>
          </div>
          <div>
            <label [(ngModel)]="settings.pcapLogEnabled" [nzDisabled]="!machinaToggle" nz-checkbox>
              {{'SETTINGS.Enable_auto_log_check' | translate}}
            </label>
          </div>
          <div>
            <label [(ngModel)]="settings.playerMetricsEnabled" [nzDisabled]="!machinaToggle" nz-checkbox nz-tooltip
                   [nzTooltipTitle]="'SETTINGS.Metrics_capture_tooltip' | translate">
              {{'SETTINGS.Enable_metrics_capture' | translate}}
            </label>
            <i>{{'SETTINGS.Enable_metrics_capture_explain' | translate}}</i>
          </div>
          <nz-form-item nzFlex *ngIf="settings.playerMetricsEnabled" fxLayoutAlign="flex-start center">
            <nz-form-label nzFor="ignored-inventories">{{'SETTINGS.Metrics_capture_folder' | translate}}</nz-form-label>
            <nz-form-control>
              <nz-input-group [nzAddOnBefore]="metricsPath">
                <button nz-button nzType="primary" (click)="changeMetricsPath()" class="corners-left">{{'COMMON.Change' | translate}}</button>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </nz-card>

      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Character_tracking' | translate"></nz-divider>
      </div>
      <div>
        <label [(ngModel)]="settings.followIngameCharacterSwitches" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Switch_tc_when_switch_ingame_character' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.showOthercharacterInventoriesInList" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Show_other_characters_inventories_in_list' | translate}}
        </label>
      </div>

      <button (click)="settings.ignoredContentIds = []" nz-button>
        {{'SETTINGS.Reset_ignored_characters' | translate}}
      </button>

      <button (click)="resetLinkedChars()" nz-button nzDanger nz-popconfirm [nzPopconfirmTitle]="'Confirmation' | translate">
        {{'SETTINGS.Reset_contentid_characters' | translate}}
      </button>

      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Inventory_tracking' | translate"></nz-divider>
      </div>
      <div>
        <label [(ngModel)]="settings.clearInventoryOnStartup" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Clear_inventory_on_startup' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.enableAutofillByDefault" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Enable_autofill_by_default' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.enableAutofillNotificationByDefault" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Enable_autofill_notification_by_default' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.enableAutofillHQFilter" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Enable_autofill_hq_filter' | translate}}
        </label>
      </div>
      <div>
        <label [(ngModel)]="settings.enableAutofillNQFilter" [nzDisabled]="!machinaToggle" nz-checkbox>
          {{'SETTINGS.Enable_autofill_nq_filter' | translate}}
        </label>
      </div>

      <nz-form-item nzFlex *ngIf="machinaToggle" fxLayoutAlign="flex-start center">
        <nz-form-label nzFor="ignored-inventories">{{'SETTINGS.Char_data_folder' | translate}}</nz-form-label>
        <nz-form-control>
          <nz-input-group [nzAddOnBefore]="watchFilesPath">
            <button nz-button nzType="primary" (click)="changeWatchFilesPath()" class="corners-left">{{'COMMON.Change' | translate}}</button>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item nzFlex *ngIf="inventories$ | async as inventories" fxLayoutAlign="flex-start center">
        <nz-form-label nzFor="ignored-inventories">{{'SETTINGS.Ignored_inventories' | translate}}</nz-form-label>
        <nz-select [(ngModel)]="ignoredInventories" (ngModelChange)="settings.ignoredInventories = ignoredInventories"
                   class="ignored-inventories" id="ignored-inventories" nzShowSearch nzMode="multiple" nzAllowClear>
          <nz-option *ngFor="let inventory of inventories" [nzLabel]="inventory | translate" [nzValue]="inventory">
          </nz-option>
        </nz-select>
      </nz-form-item>

      <div>
        <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Tooling' | translate"></nz-divider>
      </div>
      <button (click)="openDesktopConsole()" nz-button>
        {{'SETTINGS.Show_devtools' | translate}}
      </button>
      <button (click)="clearCache()" nz-button>
        {{'SETTINGS.Clear_cache' | translate}}
      </button>
      <ng-container *ngIf="(user$ | async)?.sekrit">
        <div>
          <nz-divider nzOrientation="left" [nzText]="'SETTINGS.CATEGORIES.Sekrit' | translate"></nz-divider>
        </div>
        <nz-input-group nzSearch nzSize="default" [nzAddOnAfter]="suffixButton">
          <input [(ngModel)]="settings.xivapiKey" nz-input
                 placeholder="XIVAPI Private key (saved in localstorage, not database, never database)"
                 type="text">
        </nz-input-group>
        <ng-template #suffixButton>
          <button nz-button nzType="primary" nzSize="default" (click)="startMappy()" nzSearch>{{'COMMON.Apply' | translate}}</button>
        </ng-template>
        <label nz-checkbox [(ngModel)]="settings.enableMappy" (ngModelChange)="onMappyEnableChange($event)">Enable mappy</label>
      </ng-container>
    </div>
  </nz-tab>
</nz-tabset>
