<div fxLayout="column">
  <div *ngFor="let tip of tips; let last = last">
    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="24px">
      <div>
        <div [ngSwitch]="tip.type">
          <i *ngSwitchCase="0" [nzTwotoneColor]="'red'" nz-icon nzTheme="twotone" nzType="exclamation-circle"></i>
          <i *ngSwitchCase="1" [nzTwotoneColor]="'yellow'" nz-icon nzTheme="twotone" nzType="warning"></i>
          <i *ngSwitchCase="2" nz-icon nzTheme="twotone" nzType="info-circle"></i>
        </div>
      </div>
      <div>{{('SIMULATOR.ROTATION_TIPS.' + tip.message) | translate: tip.messageParams(result)}}</div>
    </div>
    <nz-divider *ngIf="!last"></nz-divider>
  </div>
</div>
