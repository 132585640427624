export const beastTribeNpcs = [
  // Sylphic Vendor
  1005569,
  // Kobald Vendor
  1008909,
  // Sahagin Vendo
  1008907,
  // Amalj'aa Vendor
  1005554,
  // Ixali Vendor
  1009205,
  // Vath Stickpeddler
  1016804,
  // Luna Vanu
  1016093,
  // Mogmul Mogbelly
  1017172,
  // Shikitahe
  1024219,
  // Madhura
  1024774,
  // Gyosho
  1025604,
  // Mizutt
  1033714
];
