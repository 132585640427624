<p>{{'PACKET_CAPTURE.Rawsock_needs_admin_description' | translate}}</p>
<nz-divider></nz-divider>
<div fxLayout="row" fxLayoutGap="10px">
  <div>
    <button nz-button nzType="primary" (click)="switchToWinpcap()">{{'PACKET_CAPTURE.Switch_to_winpcap' | translate}}</button>
  </div>
  <div>
    <button nz-button nzType="danger" (click)="shutdownPcap()">{{'PACKET_CAPTURE.Disable_pcap' | translate}}</button>
  </div>
</div>

