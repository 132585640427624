<app-simulator *ngIf="recipe$ | async as recipe; else loading"
  [itemId]="(item$ | async)?.id"
  [recipe]="recipe"
  [thresholds]="thresholds$ | async"
  [routeStats]="stats$ | async"
  [routeConsumables]="consumables$ | async">
</app-simulator>
<ng-template #loading>
  <app-page-loader></app-page-loader>
</ng-template>
