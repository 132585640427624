<div *ngIf="servers$ | async as availableServers; else loading">
  <nz-form-item>
    <nz-form-label nzFor="serverName" nzRequired>{{'Server_name' | translate}}</nz-form-label>
    <input [formControl]="selectedServer" [nzAutocomplete]="auto" id="serverName" nz-input type="text">
    <nz-autocomplete #auto>
      <nz-auto-option *ngFor="let server of autoCompleteRows$ | async" [nzValue]="server">
        {{server}}
      </nz-auto-option>
    </nz-autocomplete>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="characterName" nzRequired>{{'Character_name' | translate}}
    </nz-form-label>
    <input [formControl]="characterName" id="characterName" nz-input type="text">
  </nz-form-item>
</div>

<div *ngIf="result$ | async as results" class="results">
  <app-page-loader [loading]="loadingResults">
    <nz-list *ngIf="results.length > 0; else noResults"
             [nzDataSource]="results"
             [nzItemLayout]="'horizontal'"
             [nzRenderItem]="characterTemplate">
      <ng-template #characterTemplate let-user>
        <nz-list-item [nzActions]="[selectAction]">
          <ng-template #selectAction>
            <button (click)="pickUser(user)" nz-button nzType="primary">{{'COMMON.Select' | translate}}</button>
          </ng-template>
          <nz-list-item-meta
            [nzAvatar]="avatar"
            [nzDescription]="user.userId"
            [nzTitle]="user.characterName">
            <ng-template #avatar>
              <app-user-avatar [userId]="user.userId"></app-user-avatar>
            </ng-template>
          </nz-list-item-meta>
        </nz-list-item>
      </ng-template>
    </nz-list>
    <ng-template #noResults>
      <span *ngIf="!loadingResults">{{'PERMISSIONS.User_not_found' | translate}}</span>
    </ng-template>
  </app-page-loader>
</div>

<ng-container *ngIf="!hideContacts">

  <nz-divider></nz-divider>

  <div *ngIf="contacts$ | async as contacts">
    <nz-list [nzDataSource]="contacts" [nzHeader]="'PROFILE.Contacts' | translate" [nzRenderItem]="contactTemplate">
      <ng-template #contactTemplate let-contact>
        <nz-list-item [nzActions]="[selectAction]">
          <ng-template #selectAction>
            <button (click)="pickUser({userId: contact})" nz-button
                    nzType="primary">{{'COMMON.Select' | translate}}</button>
          </ng-template>
          <nz-list-item-meta
            [nzAvatar]="avatar"
            [nzDescription]="contact"
            [nzTitle]="contact | characterName | async">
            <ng-template #avatar>
              <app-user-avatar [userId]="contact"></app-user-avatar>
            </ng-template>
          </nz-list-item-meta>
        </nz-list-item>
      </ng-template>
    </nz-list>
  </div>
</ng-container>

<ng-template #loading>
  <nz-spin></nz-spin>
</ng-template>
