<p>
  {{'LIST.COMPLETION_POPUP.Content' | translate}}
</p>
<div fxLayout="row wrap" fxLayoutGap="5px">
  <button (nzOnConfirm)="clearList()" [nzPopconfirmTitle]="'Confirmation' | translate" nz-button nz-popconfirm nzType="danger">
    {{'LIST.COMPLETION_POPUP.Clear' | translate}}
  </button>
  <button (nzOnConfirm)="deleteList()" [nzPopconfirmTitle]="'Confirmation' | translate" nz-button nz-popconfirm nzType="danger">
    {{'LIST.COMPLETION_POPUP.Delete' | translate}}
  </button>
  <button (nzOnConfirm)="archiveList()" [nzPopconfirmTitle]="'Confirmation' | translate" nz-button nz-popconfirm nzType="primary">
    {{'LIST.COMPLETION_POPUP.Archive' | translate}}
  </button>
  <button (nzOnConfirm)="resetList()" [nzPopconfirmTitle]="'Confirmation' | translate" nz-button nz-popconfirm nzType="primary">
    {{'LIST.COMPLETION_POPUP.Reset' | translate}}
  </button>
  <button (click)="close()" nz-button>
    {{'LIST.COMPLETION_POPUP.Nothing' | translate}}
  </button>
</div>
