export const weatherIndex = {
  "1": [
    {
      "rate": 5,
      "weatherId": 7
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 55,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "2": [
    {
      "rate": 5,
      "weatherId": 7
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 55,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "3": [
    {
      "rate": 5,
      "weatherId": 9
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 55,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "4": [
    {
      "rate": 5,
      "weatherId": 9
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 55,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "5": [
    {
      "rate": 5,
      "weatherId": 4
    },
    {
      "rate": 10,
      "weatherId": 10
    },
    {
      "rate": 25,
      "weatherId": 9
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "6": [
    {
      "rate": 5,
      "weatherId": 4
    },
    {
      "rate": 10,
      "weatherId": 8
    },
    {
      "rate": 25,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "7": [
    {
      "rate": 40,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 3
    },
    {
      "rate": 95,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "8": [
    {
      "rate": 40,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 3
    },
    {
      "rate": 95,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "9": [
    {
      "rate": 40,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 3
    },
    {
      "rate": 95,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "10": [
    {
      "rate": 15,
      "weatherId": 11
    },
    {
      "rate": 55,
      "weatherId": 1
    },
    {
      "rate": 75,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 3
    },
    {
      "rate": 95,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "11": [
    {
      "rate": 40,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 70,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 4
    },
    {
      "rate": 85,
      "weatherId": 7
    },
    {
      "rate": 100,
      "weatherId": 8
    }
  ],
  "12": [
    {
      "rate": 20,
      "weatherId": 14
    },
    {
      "rate": 60,
      "weatherId": 1
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 90,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "13": [
    {
      "rate": 5,
      "weatherId": 1
    },
    {
      "rate": 20,
      "weatherId": 2
    },
    {
      "rate": 50,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "14": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "15": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "16": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 5
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "17": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 5
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "18": [
    {
      "rate": 5,
      "weatherId": 4
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 90,
      "weatherId": 3
    },
    {
      "rate": 95,
      "weatherId": 7
    },
    {
      "rate": 100,
      "weatherId": 8
    }
  ],
  "19": [
    {
      "rate": 10,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 3
    },
    {
      "rate": 90,
      "weatherId": 5
    },
    {
      "rate": 100,
      "weatherId": 6
    }
  ],
  "20": [
    {
      "rate": 30,
      "weatherId": 1
    },
    {
      "rate": 50,
      "weatherId": 2
    },
    {
      "rate": 70,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 4
    },
    {
      "rate": 90,
      "weatherId": 9
    },
    {
      "rate": 100,
      "weatherId": 10
    }
  ],
  "21": [
    {
      "rate": 20,
      "weatherId": 16
    },
    {
      "rate": 60,
      "weatherId": 15
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 1
    },
    {
      "rate": 90,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "22": [
    {
      "rate": 15,
      "weatherId": 3
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 60,
      "weatherId": 17
    },
    {
      "rate": 75,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "23": [
    {
      "rate": 100,
      "weatherId": 29
    }
  ],
  "24": [
    {
      "rate": 30,
      "weatherId": 1
    },
    {
      "rate": 50,
      "weatherId": 2
    },
    {
      "rate": 70,
      "weatherId": 3
    },
    {
      "rate": 85,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "25": [
    {
      "rate": 100,
      "weatherId": 26
    }
  ],
  "26": [
    {
      "rate": 100,
      "weatherId": 28
    }
  ],
  "27": [
    {
      "rate": 100,
      "weatherId": 15
    }
  ],
  "28": [
    {
      "rate": 100,
      "weatherId": 3
    }
  ],
  "29": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 10
    }
  ],
  "30": [
    {
      "rate": 100,
      "weatherId": 27
    }
  ],
  "31": [
    {
      "rate": 100,
      "weatherId": 25
    }
  ],
  "32": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "33": [
    {
      "rate": 40,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 3
    },
    {
      "rate": 95,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "34": [
    {
      "rate": 5,
      "weatherId": 3
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 55,
      "weatherId": 2
    },
    {
      "rate": 85,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "35": [
    {
      "rate": 100,
      "weatherId": 15
    }
  ],
  "36": [
    {
      "rate": 100,
      "weatherId": 5
    }
  ],
  "37": [
    {
      "rate": 100,
      "weatherId": 9
    }
  ],
  "38": [
    {
      "rate": 100,
      "weatherId": 23
    }
  ],
  "39": [
    {
      "rate": 100,
      "weatherId": 24
    }
  ],
  "40": [
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "41": [
    {
      "rate": 100,
      "weatherId": 11
    }
  ],
  "42": [
    {
      "rate": 100,
      "weatherId": 16
    }
  ],
  "43": [
    {
      "rate": 100,
      "weatherId": 22
    }
  ],
  "44": [
    {
      "rate": 100,
      "weatherId": 36
    }
  ],
  "45": [
    {
      "rate": 100,
      "weatherId": 20
    }
  ],
  "46": [
    {
      "rate": 100,
      "weatherId": 35
    }
  ],
  "47": [
    {
      "rate": 60,
      "weatherId": 15
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 1
    },
    {
      "rate": 90,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "48": [
    {
      "rate": 60,
      "weatherId": 15
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 1
    },
    {
      "rate": 90,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "49": [
    {
      "rate": 20,
      "weatherId": 16
    },
    {
      "rate": 60,
      "weatherId": 15
    },
    {
      "rate": 70,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 1
    },
    {
      "rate": 90,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "50": [
    {
      "rate": 10,
      "weatherId": 3
    },
    {
      "rate": 20,
      "weatherId": 4
    },
    {
      "rate": 30,
      "weatherId": 9
    },
    {
      "rate": 40,
      "weatherId": 11
    },
    {
      "rate": 70,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "51": [
    {
      "rate": 10,
      "weatherId": 3
    },
    {
      "rate": 20,
      "weatherId": 4
    },
    {
      "rate": 30,
      "weatherId": 7
    },
    {
      "rate": 40,
      "weatherId": 8
    },
    {
      "rate": 70,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "52": [
    {
      "rate": 10,
      "weatherId": 3
    },
    {
      "rate": 20,
      "weatherId": 6
    },
    {
      "rate": 40,
      "weatherId": 50
    },
    {
      "rate": 70,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "53": [
    {
      "rate": 30,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 70,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 4
    },
    {
      "rate": 90,
      "weatherId": 5
    },
    {
      "rate": 100,
      "weatherId": 49
    }
  ],
  "54": [
    {
      "rate": 35,
      "weatherId": 2
    },
    {
      "rate": 70,
      "weatherId": 3
    },
    {
      "rate": 100,
      "weatherId": 9
    }
  ],
  "55": [
    {
      "rate": 10,
      "weatherId": 3
    },
    {
      "rate": 20,
      "weatherId": 4
    },
    {
      "rate": 30,
      "weatherId": 7
    },
    {
      "rate": 40,
      "weatherId": 8
    },
    {
      "rate": 70,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "56": [
    {
      "rate": 100,
      "weatherId": 44
    }
  ],
  "57": [
    {
      "rate": 100,
      "weatherId": 51
    }
  ],
  "58": [
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "59": [
    {
      "rate": 15,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 7
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "60": [
    {
      "rate": 40,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 5
    },
    {
      "rate": 99,
      "weatherId": 49
    },
    {
      "rate": 100,
      "weatherId": 54
    }
  ],
  "61": [
    {
      "rate": 40,
      "weatherId": 2
    },
    {
      "rate": 60,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 5
    },
    {
      "rate": 99,
      "weatherId": 49
    },
    {
      "rate": 100,
      "weatherId": 54
    }
  ],
  "62": [
    {
      "rate": 40,
      "weatherId": 2
    },
    {
      "rate": 65,
      "weatherId": 4
    },
    {
      "rate": 90,
      "weatherId": 5
    },
    {
      "rate": 98,
      "weatherId": 49
    },
    {
      "rate": 100,
      "weatherId": 54
    }
  ],
  "63": [
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "64": [
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "65": [
    {
      "rate": 50,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "66": [
    {
      "rate": 100,
      "weatherId": 60
    }
  ],
  "67": [
    {
      "rate": 35,
      "weatherId": 2
    },
    {
      "rate": 65,
      "weatherId": 15
    },
    {
      "rate": 85,
      "weatherId": 16
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "68": [
    {
      "rate": 100,
      "weatherId": 14
    }
  ],
  "69": [
    {
      "rate": 100,
      "weatherId": 69
    }
  ],
  "70": [
    {
      "rate": 100,
      "weatherId": 54
    }
  ],
  "71": [
    {
      "rate": 30,
      "weatherId": 2
    },
    {
      "rate": 60,
      "weatherId": 4
    },
    {
      "rate": 90,
      "weatherId": 5
    },
    {
      "rate": 100,
      "weatherId": 49
    }
  ],
  "72": [
    {
      "rate": 65,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "73": [
    {
      "rate": 65,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 7
    }
  ],
  "74": [
    {
      "rate": 100,
      "weatherId": 17
    }
  ],
  "75": [
    {
      "rate": 100,
      "weatherId": 74
    }
  ],
  "76": [
    {
      "rate": 100,
      "weatherId": 84
    }
  ],
  "77": [
    {
      "rate": 100,
      "weatherId": 80
    }
  ],
  "78": [
    {
      "rate": 15,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 3
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 9
    }
  ],
  "79": [
    {
      "rate": 15,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 3
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 9
    }
  ],
  "80": [
    {
      "rate": 10,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 3
    },
    {
      "rate": 85,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 5
    },
    {
      "rate": 100,
      "weatherId": 11
    }
  ],
  "81": [
    {
      "rate": 20,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 3
    },
    {
      "rate": 90,
      "weatherId": 4
    },
    {
      "rate": 100,
      "weatherId": 10
    }
  ],
  "82": [
    {
      "rate": 10,
      "weatherId": 7
    },
    {
      "rate": 20,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "83": [
    {
      "rate": 10,
      "weatherId": 9
    },
    {
      "rate": 20,
      "weatherId": 5
    },
    {
      "rate": 35,
      "weatherId": 3
    },
    {
      "rate": 75,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "84": [
    {
      "rate": 5,
      "weatherId": 8
    },
    {
      "rate": 15,
      "weatherId": 7
    },
    {
      "rate": 25,
      "weatherId": 4
    },
    {
      "rate": 40,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "85": [
    {
      "rate": 5,
      "weatherId": 6
    },
    {
      "rate": 10,
      "weatherId": 5
    },
    {
      "rate": 17,
      "weatherId": 7
    },
    {
      "rate": 25,
      "weatherId": 4
    },
    {
      "rate": 35,
      "weatherId": 3
    },
    {
      "rate": 75,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "86": [
    {
      "rate": 100,
      "weatherId": 50
    }
  ],
  "87": [
    {
      "rate": 100,
      "weatherId": 82
    }
  ],
  "88": [
    {
      "rate": 100,
      "weatherId": 79
    }
  ],
  "89": [
    {
      "rate": 100,
      "weatherId": 88
    }
  ],
  "90": [
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "91": [
    {
      "rate": 30,
      "weatherId": 2
    },
    {
      "rate": 60,
      "weatherId": 6
    },
    {
      "rate": 90,
      "weatherId": 8
    },
    {
      "rate": 100,
      "weatherId": 15
    }
  ],
  "92": [
    {
      "rate": 100,
      "weatherId": 77
    }
  ],
  "93": [
    {
      "rate": 100,
      "weatherId": 92
    }
  ],
  "94": [
    {
      "rate": 10,
      "weatherId": 2
    },
    {
      "rate": 28,
      "weatherId": 4
    },
    {
      "rate": 46,
      "weatherId": 14
    },
    {
      "rate": 64,
      "weatherId": 15
    },
    {
      "rate": 82,
      "weatherId": 9
    },
    {
      "rate": 100,
      "weatherId": 16
    }
  ],
  "95": [
    {
      "rate": 100,
      "weatherId": 101
    }
  ],
  "96": [
    {
      "rate": 10,
      "weatherId": 2
    },
    {
      "rate": 28,
      "weatherId": 14
    },
    {
      "rate": 46,
      "weatherId": 9
    },
    {
      "rate": 64,
      "weatherId": 16
    },
    {
      "rate": 82,
      "weatherId": 49
    },
    {
      "rate": 100,
      "weatherId": 15
    }
  ],
  "97": [
    {
      "rate": 100,
      "weatherId": 12
    }
  ],
  "98": [
    {
      "rate": 100,
      "weatherId": 102
    }
  ],
  "99": [
    {
      "rate": 100,
      "weatherId": 113
    }
  ],
  "100": [
    {
      "rate": 12,
      "weatherId": 2
    },
    {
      "rate": 34,
      "weatherId": 8
    },
    {
      "rate": 56,
      "weatherId": 17
    },
    {
      "rate": 78,
      "weatherId": 10
    },
    {
      "rate": 100,
      "weatherId": 15
    }
  ],
  "101": [
    {
      "rate": 100,
      "weatherId": 119
    }
  ],
  "102": [
    {
      "rate": 100,
      "weatherId": 118
    }
  ],
  "103": [
    {
      "rate": 100,
      "weatherId": 122
    }
  ],
  "104": [
    {
      "rate": 100,
      "weatherId": 125
    }
  ],
  "105": [
    {
      "rate": 100,
      "weatherId": 120
    }
  ],
  "106": [
    {
      "rate": 20,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 3
    },
    {
      "rate": 85,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 7
    },
    {
      "rate": 100,
      "weatherId": 10
    }
  ],
  "107": [
    {
      "rate": 10,
      "weatherId": 6
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 45,
      "weatherId": 3
    },
    {
      "rate": 85,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "108": [
    {
      "rate": 45,
      "weatherId": 2
    },
    {
      "rate": 60,
      "weatherId": 3
    },
    {
      "rate": 70,
      "weatherId": 11
    },
    {
      "rate": 80,
      "weatherId": 14
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "109": [
    {
      "rate": 10,
      "weatherId": 7
    },
    {
      "rate": 20,
      "weatherId": 4
    },
    {
      "rate": 35,
      "weatherId": 3
    },
    {
      "rate": 45,
      "weatherId": 10
    },
    {
      "rate": 60,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "110": [
    {
      "rate": 10,
      "weatherId": 4
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 49
    },
    {
      "rate": 45,
      "weatherId": 1
    },
    {
      "rate": 85,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 3
    }
  ],
  "111": [
    {
      "rate": 20,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "112": [
    {
      "rate": 20,
      "weatherId": 1
    },
    {
      "rate": 60,
      "weatherId": 2
    },
    {
      "rate": 75,
      "weatherId": 3
    },
    {
      "rate": 85,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 7
    },
    {
      "rate": 100,
      "weatherId": 10
    }
  ],
  "113": [
    {
      "rate": 10,
      "weatherId": 6
    },
    {
      "rate": 20,
      "weatherId": 7
    },
    {
      "rate": 30,
      "weatherId": 4
    },
    {
      "rate": 45,
      "weatherId": 3
    },
    {
      "rate": 85,
      "weatherId": 2
    },
    {
      "rate": 100,
      "weatherId": 1
    }
  ],
  "114": [
    {
      "rate": 100,
      "weatherId": 105
    }
  ],
  "115": [
    {
      "rate": 100,
      "weatherId": 10
    }
  ],
  "116": [
    {
      "rate": 100,
      "weatherId": 127
    }
  ],
  "117": [
    {
      "rate": 50,
      "weatherId": 2
    },
    {
      "rate": 80,
      "weatherId": 5
    },
    {
      "rate": 90,
      "weatherId": 7
    },
    {
      "rate": 95,
      "weatherId": 6
    },
    {
      "rate": 100,
      "weatherId": 4
    }
  ],
  "118": [
    {
      "rate": 50,
      "weatherId": 1
    },
    {
      "rate": 100,
      "weatherId": 2
    }
  ],
  "119": [
    {
      "rate": 45,
      "weatherId": 2
    },
    {
      "rate": 63,
      "weatherId": 3
    },
    {
      "rate": 80,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 7
    },
    {
      "rate": 100,
      "weatherId": 8
    }
  ],
  "120": [
    {
      "rate": 40,
      "weatherId": 2
    },
    {
      "rate": 55,
      "weatherId": 3
    },
    {
      "rate": 70,
      "weatherId": 4
    },
    {
      "rate": 95,
      "weatherId": 5
    },
    {
      "rate": 100,
      "weatherId": 6
    }
  ],
  "121": [
    {
      "rate": 35,
      "weatherId": 2
    },
    {
      "rate": 50,
      "weatherId": 3
    },
    {
      "rate": 65,
      "weatherId": 4
    },
    {
      "rate": 85,
      "weatherId": 15
    },
    {
      "rate": 100,
      "weatherId": 16
    }
  ],
  "122": [
    {
      "rate": 82,
      "weatherId": 2
    },
    {
      "rate": 87,
      "weatherId": 3
    },
    {
      "rate": 92,
      "weatherId": 4
    },
    {
      "rate": 97,
      "weatherId": 11
    },
    {
      "rate": 100,
      "weatherId": 14
    }
  ],
  "123": [
    {
      "rate": 100,
      "weatherId": 138
    }
  ],
  "124": [
    {
      "rate": 52,
      "weatherId": 2
    },
    {
      "rate": 64,
      "weatherId": 7
    },
    {
      "rate": 76,
      "weatherId": 5
    },
    {
      "rate": 88,
      "weatherId": 9
    },
    {
      "rate": 100,
      "weatherId": 11
    }
  ],
  "125": [
    {
      "rate": 100,
      "weatherId": 137
    }
  ],
  "126": [
    {
      "rate": 100,
      "weatherId": 147
    }
  ]
};