export enum RotationTag {
  SPECIALIST = 'Specialist',
  NOT_SPECIALIST = 'Not_specialist',

  TEALESS = 'Tealess',
  FOODLESS = 'Foodless',

  MAKERS_MARK = 'Makers_Mark',
  MUSCLE_MEMORY = 'Muscle_memory'
}
