<nz-alert nzType="warning" nzBanner *ngIf="showWarning"
          [nzMessage]="'COMMISSIONS.CREATION.Warning' | translate"
          [nzDescription]="'COMMISSIONS.CREATION.Warning_description' | translate"
          nzCloseable
          (nzOnClose)="hideWarning()"
          [nzCloseText]="'Close' | translate"></nz-alert>
<form nz-form nzLayout="vertical" [formGroup]="form">
  <nz-form-item>
    <nz-form-label nzFor="name" nzRequired>{{'COMMISSIONS.CREATION.Name' | translate}}</nz-form-label>
    <nz-form-control nzHasFeedback [nzErrorTip]="nameErrorTpl">
      <input nz-input formControlName="name" type="text" id="name" [placeholder]="'COMMISSIONS.CREATION.Name_placeholder' | translate">
      <ng-template #nameErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">{{'COMMISSIONS.CREATION.Name_required_error' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="description" nzRequired>{{'COMMISSIONS.CREATION.Description' | translate}}</nz-form-label>
    <nz-form-control nzHasFeedback [nzErrorTip]="descriptionErrorTpl">
      <textarea nz-input formControlName="description" rows="4" id="description"
                [placeholder]="'COMMISSIONS.CREATION.Description_placeholder' | translate">
      </textarea>
      <ng-template #descriptionErrorTpl let-control>
        <ng-container *ngIf="control.hasError('maxlength')">{{'COMMISSIONS.CREATION.Description_max_length_error' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label>{{'COMMISSIONS.CREATION.Tags' | translate}}</nz-form-label>
    <nz-form-control>
      <nz-select class="filter-select" formControlName="tags" nzAllowClear nzMode="multiple" nzShowSearch>
        <nz-option *ngFor="let tag of commissionTags" [nzLabel]="tag.label | translate" [nzValue]="tag.value">
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="price" nzRequired>{{'COMMISSIONS.CREATION.Price' | translate}}</nz-form-label>
    <nz-form-control nzValidateStatus="validating" [nzValidatingTip]="priceTip">
      <nz-input-number formControlName="price" type="number" id="price" nzMin="0" nzMax="999999999"></nz-input-number>
      <ng-template #priceTip>
        {{'COMMISSIONS.CREATION.Price_tip' | translate}}
      </ng-template>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="includesMaterials">{{'COMMISSIONS.CREATION.Only_crafting' | translate}}</nz-form-label>
    <nz-form-control>
      <label nz-checkbox [nzDisabled]="form.value.requiresOnlyMaterials" formControlName="includesMaterials" id="includesMaterials">{{'COMMISSIONS.CREATION.Includes_materials_label' | translate}}</label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="requiresOnlyMaterials">{{'COMMISSIONS.CREATION.Only_materials' | translate}}</nz-form-label>
    <nz-form-control>
      <label nz-checkbox [nzDisabled]="form.value.includesMaterials" formControlName="requiresOnlyMaterials" id="requiresOnlyMaterials">{{'COMMISSIONS.CREATION.Only_requires_materials' | translate}}</label>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label nzFor="contactInformations" nzRequired>{{'COMMISSIONS.CREATION.Contact_info' | translate}}</nz-form-label>
    <nz-form-control nzHasFeedback [nzErrorTip]="contactInformationsErrorTpl">
      <textarea nz-input formControlName="contactInformations" rows="4" id="contactInformations"
                [placeholder]="'COMMISSIONS.CREATION.Contact_info_placeholder' | translate">
      </textarea>
      <ng-template #contactInformationsErrorTpl let-control>
        <ng-container *ngIf="control.hasError('required')">{{'COMMISSIONS.CREATION.Contact_info_required_error' | translate}}</ng-container>
      </ng-template>
    </nz-form-control>
  </nz-form-item>
</form>
<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="flex-start center">
  <div>
    <button [disabled]="!form.valid" (click)="submit()" nzType="primary" nz-button>{{'Confirm' | translate}}
    </button>
  </div>
  <div *ngIf="commission.items?.length">
    <button [disabled]="!form.valid" [clipboard]="getCreationLink"
            nz-tooltip [nzTooltipTitle]="'COMMISSIONS.CREATION.Copy_link_tooltip' | translate"
            [clipboardSuccessMessage]="'COMMISSIONS.CREATION.Link_copied' | translate"
            nz-button>{{'COMMISSIONS.CREATION.Copy_link' | translate}}
    </button>
  </div>
  <div>
    <button (click)="close()" nz-button>{{'Cancel' | translate}}
    </button>
  </div>
</div>
