export const aetherstream = {
  "1": {
    "x": 0,
    "y": 0
  },
  "2": {
    "x": 1560,
    "y": 720
  },
  "3": {
    "x": 1540,
    "y": 820
  },
  "4": {
    "x": 1700,
    "y": 820
  },
  "5": {
    "x": 1580,
    "y": 920
  },
  "6": {
    "x": 1520,
    "y": 980
  },
  "7": {
    "x": 1400,
    "y": 720
  },
  "8": {
    "x": 640,
    "y": 1200
  },
  "9": {
    "x": 1260,
    "y": 1380
  },
  "10": {
    "x": 780,
    "y": 1220
  },
  "11": {
    "x": 820,
    "y": 1120
  },
  "12": {
    "x": 780,
    "y": 1040
  },
  "13": {
    "x": 600,
    "y": 1100
  },
  "14": {
    "x": 540,
    "y": 1100
  },
  "15": {
    "x": 760,
    "y": 1000
  },
  "16": {
    "x": 680,
    "y": 960
  },
  "17": {
    "x": 1120,
    "y": 1260
  },
  "18": {
    "x": 1360,
    "y": 1210
  },
  "19": {
    "x": 1360,
    "y": 1340
  },
  "20": {
    "x": 1360,
    "y": 1440
  },
  "21": {
    "x": 1280,
    "y": 1160
  },
  "22": {
    "x": 1260,
    "y": 1100
  },
  "23": {
    "x": 1180,
    "y": 580
  },
  "24": {
    "x": 1240,
    "y": 860
  },
  "25": {
    "x": 0,
    "y": 0
  },
  "26": {
    "x": 0,
    "y": 0
  },
  "27": {
    "x": 0,
    "y": 0
  },
  "28": {
    "x": 0,
    "y": 0
  },
  "29": {
    "x": 0,
    "y": 0
  },
  "30": {
    "x": 0,
    "y": 0
  },
  "31": {
    "x": 0,
    "y": 0
  },
  "32": {
    "x": 0,
    "y": 0
  },
  "33": {
    "x": 0,
    "y": 0
  },
  "34": {
    "x": 0,
    "y": 0
  },
  "35": {
    "x": 0,
    "y": 0
  },
  "36": {
    "x": 0,
    "y": 0
  },
  "37": {
    "x": 0,
    "y": 0
  },
  "38": {
    "x": 0,
    "y": 0
  },
  "39": {
    "x": 0,
    "y": 0
  },
  "40": {
    "x": 0,
    "y": 0
  },
  "41": {
    "x": 0,
    "y": 0
  },
  "42": {
    "x": 0,
    "y": 0
  },
  "43": {
    "x": 0,
    "y": 0
  },
  "44": {
    "x": 0,
    "y": 0
  },
  "45": {
    "x": 0,
    "y": 0
  },
  "46": {
    "x": 0,
    "y": 0
  },
  "47": {
    "x": 0,
    "y": 0
  },
  "48": {
    "x": 0,
    "y": 0
  },
  "49": {
    "x": 0,
    "y": 0
  },
  "50": {
    "x": 0,
    "y": 0
  },
  "51": {
    "x": 0,
    "y": 0
  },
  "52": {
    "x": 700,
    "y": 1170
  },
  "53": {
    "x": 1290,
    "y": 1230
  },
  "54": {
    "x": 0,
    "y": 0
  },
  "55": {
    "x": 600,
    "y": 1240
  },
  "56": {
    "x": 640,
    "y": 1200
  },
  "57": {
    "x": 1560,
    "y": 720
  },
  "58": {
    "x": 1260,
    "y": 1380
  },
  "59": {
    "x": 640,
    "y": 1200
  },
  "60": {
    "x": 1560,
    "y": 720
  },
  "61": {
    "x": 1260,
    "y": 1380
  },
  "62": {
    "x": 1320,
    "y": 1480
  },
  "63": {
    "x": 0,
    "y": 0
  },
  "64": {
    "x": 0,
    "y": 0
  },
  "65": {
    "x": 0,
    "y": 0
  },
  "66": {
    "x": 0,
    "y": 0
  },
  "67": {
    "x": 0,
    "y": 0
  },
  "68": {
    "x": 0,
    "y": 0
  },
  "69": {
    "x": 0,
    "y": 0
  },
  "70": {
    "x": 1150,
    "y": 450
  },
  "71": {
    "x": 1000,
    "y": 400
  },
  "72": {
    "x": 1450,
    "y": 300
  },
  "73": {
    "x": 1450,
    "y": 260
  },
  "74": {
    "x": 1300,
    "y": 230
  },
  "75": {
    "x": 550,
    "y": 430
  },
  "76": {
    "x": 700,
    "y": 400
  },
  "77": {
    "x": 650,
    "y": 400
  },
  "78": {
    "x": 600,
    "y": 350
  },
  "79": {
    "x": 580,
    "y": 340
  },
  "80": {
    "x": 0,
    "y": 0
  },
  "81": {
    "x": 0,
    "y": 0
  },
  "82": {
    "x": 0,
    "y": 0
  },
  "83": {
    "x": 0,
    "y": 0
  },
  "84": {
    "x": 0,
    "y": 0
  },
  "85": {
    "x": 0,
    "y": 0
  },
  "86": {
    "x": 0,
    "y": 0
  },
  "87": {
    "x": 0,
    "y": 0
  },
  "88": {
    "x": 0,
    "y": 0
  },
  "89": {
    "x": 0,
    "y": 0
  },
  "90": {
    "x": 0,
    "y": 0
  },
  "91": {
    "x": 0,
    "y": 0
  },
  "92": {
    "x": 0,
    "y": 0
  },
  "93": {
    "x": 0,
    "y": 0
  },
  "94": {
    "x": 0,
    "y": 0
  },
  "95": {
    "x": 0,
    "y": 0
  },
  "96": {
    "x": 10900,
    "y": 675
  },
  "97": {
    "x": 10900,
    "y": 675
  },
  "98": {
    "x": 1984,
    "y": 777
  },
  "99": {
    "x": 2116,
    "y": 870
  },
  "100": {
    "x": 2323,
    "y": 768
  },
  "101": {
    "x": 2270,
    "y": 952
  },
  "102": {
    "x": 2459,
    "y": 829
  },
  "103": {
    "x": 2632,
    "y": 925
  },
  "104": {
    "x": 2171,
    "y": 745
  },
  "105": {
    "x": 10501,
    "y": 388
  },
  "106": {
    "x": 10463,
    "y": 358
  },
  "107": {
    "x": 10231,
    "y": 460
  },
  "108": {
    "x": 10207,
    "y": 420
  },
  "109": {
    "x": 10357,
    "y": 234
  },
  "110": {
    "x": 10291,
    "y": 174
  },
  "111": {
    "x": 10900,
    "y": 630
  },
  "112": {
    "x": 0,
    "y": 0
  },
  "113": {
    "x": 0,
    "y": 0
  },
  "114": {
    "x": 0,
    "y": 0
  },
  "115": {
    "x": 0,
    "y": 0
  },
  "116": {
    "x": 0,
    "y": 0
  },
  "117": {
    "x": 0,
    "y": 0
  },
  "118": {
    "x": 0,
    "y": 0
  },
  "119": {
    "x": 0,
    "y": 0
  },
  "120": {
    "x": 0,
    "y": 0
  },
  "121": {
    "x": 0,
    "y": 0
  },
  "122": {
    "x": 0,
    "y": 0
  },
  "123": {
    "x": 0,
    "y": 0
  },
  "124": {
    "x": 0,
    "y": 0
  },
  "125": {
    "x": 0,
    "y": 0
  },
  "126": {
    "x": 0,
    "y": 0
  },
  "127": {
    "x": 10231,
    "y": 460
  },
  "128": {
    "x": 10291,
    "y": 174
  },
  "129": {
    "x": 0,
    "y": 0
  },
  "130": {
    "x": 0,
    "y": 0
  },
  "131": {
    "x": 0,
    "y": 0
  },
  "132": {
    "x": 554,
    "y": 10487
  },
  "133": {
    "x": 623,
    "y": 10560
  },
  "134": {
    "x": 170,
    "y": 10562
  },
  "135": {
    "x": 0,
    "y": 0
  },
  "136": {
    "x": 347,
    "y": 10462
  },
  "137": {
    "x": 254,
    "y": 10430
  },
  "138": {
    "x": 126,
    "y": 10440
  },
  "139": {
    "x": 100,
    "y": 10303
  },
  "140": {
    "x": 696,
    "y": 10737
  },
  "141": {
    "x": 589,
    "y": 10740
  },
  "142": {
    "x": 475,
    "y": 10224
  },
  "143": {
    "x": 544,
    "y": 10157
  },
  "144": {
    "x": 717,
    "y": 10344
  },
  "145": {
    "x": 755,
    "y": 10152
  },
  "146": {
    "x": 818,
    "y": 10176
  },
  "147": {
    "x": 333,
    "y": 10723
  },
  "148": {
    "x": 202,
    "y": 10776
  },
  "149": {
    "x": 0,
    "y": 0
  },
  "150": {
    "x": 0,
    "y": 0
  },
  "151": {
    "x": 0,
    "y": 0
  },
  "152": {
    "x": 0,
    "y": 0
  },
  "153": {
    "x": 0,
    "y": 0
  },
  "154": {
    "x": 0,
    "y": 0
  },
  "155": {
    "x": 0,
    "y": 0
  },
  "156": {
    "x": 0,
    "y": 0
  },
  "157": {
    "x": 0,
    "y": 0
  },
  "158": {
    "x": 0,
    "y": 0
  },
  "159": {
    "x": 0,
    "y": 0
  },
  "160": {
    "x": 0,
    "y": 0
  },
  "161": {
    "x": 713,
    "y": 10772
  },
  "162": {
    "x": 0,
    "y": 0
  },
  "163": {
    "x": 0,
    "y": 0
  }
};