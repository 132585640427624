export enum BaseParam {
  NULL,
  STRENGTH,
  DEXTERITY,
  VITALITY,
  INTELLIGENCE,
  MIND,
  PIETY,
  HP,
  MP,
  TP,
  GP,
  CP,
  PHYSICAL_DAMAGE,
  MAGIC_DAMAGE,
  DELAY,
  ADDITIONAL_EFFECT,
  ATTACK_SPEED,
  BLOCK_RATE,
  BLOCK_STRENGTH,
  TENACITY,
  ATTACK_POWER,
  DEFENSE,
  DIRECT_HIT_RATE,
  EVASION,
  MAGIC_DEFENSE,
  CRITICAL_HIT_POWER,
  CRITICAL_HIT_RESILIENCE,
  CRITICAL_HIT,
  CRITICAL_HIT_EVASION,
  SLASHING_RESISTANCE,
  PIERCING_RESISTANCE,
  BLUNT_RESISTANCE,
  PROJECTILE_RESISTANCE,
  ATTACK_MAGIC_POTENCY,
  HEALING_MAGIC_POTENCY,
  ENHANCEMENT_MAGIC_POTENCY,
  ELEMENTAL_BONUS,
  FIRE_RESISTANCE,
  ICE_RESISTANCE,
  WIND_RESISTANCE,
  EARTH_RESISTANCE,
  LIGHTNING_RESISTANCE,
  WATER_RESISTANCE,
  MAGIC_RESISTANCE,
  DETERMINATION,
  SKILL_SPEED,
  SPELL_SPEED,
  HASTE,
  MORALE,
  ENMITY,
  ENMITY_REDUCTION,
  DESYNTHESIS_SKILL_GAIN,
  EXP_BONUS,
  REGEN,
  REFRESH,
  MAIN_ATTRIBUTE,
  SECONDARY_ATTRIBUTE,
  SLOW_RESISTANCE,
  PETRIFICATION_RESISTANCE,
  PARALYSIS_RESISTANCE,
  SILENCE_RESISTANCE,
  BLIND_RESISTANCE,
  POISON_RESISTANCE,
  STUN_RESISTANCE,
  SLEEP_RESISTANCE,
  BIND_RESISTANCE,
  HEAVY_RESISTANCE,
  DOOM_RESISTANCE,
  REDUCED_DURABILITY_LOSS,
  INCREASED_SPIRITBOND_GAIN,
  CRAFTSMANSHIP,
  CONTROL,
  GATHERING,
  PERCEPTION,

}

