<div fxLayout="column" fxLayoutGap="10px">
  <label nz-checkbox [(ngModel)]="includeStats">{{'SIMULATOR.Include_stats' | translate}}</label>
  <nz-input-group nzSearch [nzAddOnAfter]="addOnAfterTemplate">
    <input type="text" nz-input [ngModel]="getLink()" readonly/>
  </nz-input-group>
  <ng-template #addOnAfterTemplate>
    <button nz-button nzSearch nzType="primary" (clipboardCopySuccess)="afterLinkCopy()"
            [clipboard]="getLink">{{'COMMON.Copy' | translate}}</button>
  </ng-template>
</div>
