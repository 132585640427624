<ng-container *ngIf="rotation$ | async as rotation">
  <div *ngIf="!rotation.notFound; else notFound" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
    <div class="buttons" fxLayout="column" fxLayout.lt-md="row wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
      <button (click)="saveRotationAsNew(rotation)" *ngIf="rotation.$key !== undefined"
              [nzTooltipTitle]="'SIMULATOR.Save_as_new' | translate" nz-button
              nz-tooltip
              tutorialStep="TUTORIAL.SIMULATOR.Save_as_new"
              tutorialStepAlign="right"
              tutorialStepIndex="0"
              nzShape="circle" nzType="primary">
        <i nz-icon nzType="copy"></i>
      </button>
      <button (click)="saveRotation(rotation)" *ngIf="(permissionLevel$ | async) >= 30"
              [nzTooltipTitle]="'SIMULATOR.Persist' | translate" nz-button nz-tooltip
              nzShape="circle" nzType="primary">
        <i nz-icon nzType="save"></i>
      </button>
      <ng-container *ngIf="dirty">
        <button (nzOnConfirm)="resetToSavedRotation(rotation)"
                [nzTooltipTitle]="'SIMULATOR.Reset_saved' | translate"
                [nzPopconfirmTitle]="'Confirmation' | translate"
                nz-button nz-popconfirm nz-tooltip
                nzShape="circle"
                nzType="primary">
          <i nz-icon nzType="rollback" nzTheme="outline"></i>
        </button>
      </ng-container>
      <button (click)="changeRecipe(rotation)" *ngIf="custom" [nzTooltipTitle]="'SIMULATOR.Convert_rotation' | translate"
              nz-button nz-tooltip
              nzShape="circle"
              nzType="primary">
        <i nz-icon nzType="redo"></i>
      </button>
      <button (nzOnConfirm)="actions$.next([]);this.dirty = true;dirtyFacade.removeEntry('simulator', dirtyScope.PAGE);"
              [nzTooltipTitle]="'SIMULATOR.Reset' | translate"
              [nzPopconfirmTitle]="'Confirmation' | translate"
              nz-button nz-popconfirm nz-tooltip
              nzShape="circle"
              nzType="primary">
        <i nz-icon nzType="reload"></i>
      </button>
      <button (click)="importFromCraftingOptimizer()"
              [nzTooltipTitle]="'SIMULATOR.Import_from_crafting_optimizer' | translate"
              nz-button nz-tooltip
              nzShape="circle"
              nzType="primary">
        <i nz-icon nzType="download"></i>
      </button>
      <button [clipboardSuccessMessage]="'SIMULATOR.Export_copied' | translate"
              [clipboard]="getCraftOptExportString"
              [disabled]="actions$.value.length === 0"
              [nzTooltipTitle]="'SIMULATOR.Export_crafting_optimizer' | translate"
              nz-button nz-tooltip
              nzShape="circle"
              nzType="primary">
        <i nz-icon nzType="upload"></i>
      </button>
      <button (click)="importFromXIVMacro()" [nzTooltipTitle]="'SIMULATOR.Import_macro' | translate" nz-button nz-tooltip
              nzShape="circle"
              tutorialStep="TUTORIAL.SIMULATOR.Import_ingame_macro"
              tutorialStepAlign="right"
              tutorialStepIndex="1"
              nzType="primary">
        <i nz-icon nzType="select"></i>
      </button>
      <button (click)="openMacroPopup(simulation)" *ngIf="simulation$ | async as simulation"
              [disabled]="actions$.value.length === 0" [nzTooltipTitle]="'SIMULATOR.Generate_ingame_macro' | translate"
              nz-button
              nz-tooltip
              nzShape="circle"
              tutorialStep="TUTORIAL.SIMULATOR.Generate_ingame_macro"
              tutorialStepAlign="right"
              tutorialStepIndex="2"
              nzType="primary" class="text-icon">
        <span class="text-icon">XIV</span>
      </button>
      <button (click)="openMinStatsPopup(simulation)" *ngIf="simulation$ | async as simulation"
              [nzTooltipTitle]="'SIMULATOR.Min_stats' | translate" nz-button
              nz-tooltip
              nzShape="circle"
              nzType="primary">
        <i nz-icon nzType="safety-certificate"></i>
      </button>
      <button (click)="openStepByStepReportPopup(resultData)" *ngIf="result$ | async as resultData"
              [nzTooltipTitle]="'SIMULATOR.Step_by_step_report' | translate" nz-button
              nz-tooltip
              nzShape="circle"
              nzType="primary">
        <i nz-icon nzType="ordered-list"></i>
      </button>
      <ng-container *ngIf="crafterStats$ | async as stats">
        <button (click)="openSharePopup(rotation, stats)"
                *ngIf="rotation.$key"
                [nzShape]="'circle'"
                [nzTooltipTitle]="'SIMULATOR.Share_button_tooltip' | translate"
                tutorialStep="TUTORIAL.SIMULATOR.Share_with_stats"
                tutorialStepAlign="right"
                tutorialStepIndex="3"
                [nzType]="'primary'"
                nz-button nz-tooltip>
          <i nz-icon nzType="share-alt"></i>
        </button>
      </ng-container>
      <!--      <button (click)="openRotationSolver()" [nzShape]="'circle'"-->
      <!--              [nzTitle]="'SIMULATOR.Rotation_solver' | translate"-->
      <!--              [nzType]="'primary'"-->
      <!--              nz-button-->
      <!--              nz-tooltip>-->
      <!--        <i nz-icon nzTheme="outline" nzType="reconciliation"></i>-->
      <!--      </button>-->
      <ng-container *ngIf="tips$ | async as tips">
        <nz-badge [nzCount]="tips.length">
          <button (click)="showRotationTips(tips, result)" *ngIf="result$ | async as result"
                  [disabled]="tips?.length === 0"
                  [nzShape]="'circle'"
                  [nzTooltipTitle]="'SIMULATOR.Rotation_tips' | translate" [nzType]="'primary'"
                  nz-button
                  nz-tooltip>
            <i nz-icon nzType="bulb"></i>
          </button>
        </nz-badge>
      </ng-container>
      <app-favorite-button *ngIf="rotation.$key" [key]="rotation.$key" type="rotations"></app-favorite-button>
      <ng-container *ngIf="simulation$ | async as simulation">
        <ng-container *ngIf="crafterStats$ | async as stats">
          <button (click)="openCommunityRotationConfiguration(rotation, simulation, stats)"
                  *ngIf="rotation.$key && ((permissionLevel$ | async) === 40)"
                  [nzShape]="'circle'"
                  [nzTooltipTitle]="'SIMULATOR.COMMUNITY_ROTATIONS.Configuration_popup' | translate"
                  nz-button
                  nz-tooltip
                  nzType="primary">
            <img class="man-attacked-by-tetris-block button-icon" src="./assets/icons/svg/thing.svg"/>
          </button>
        </ng-container>
      </ng-container>
      <button (nzOnConfirm)="openOverlay(rotation)" *ngIf="platformService.isDesktop() && rotation.$key"
              [nzShape]="'circle'" [nzPopconfirmTitle]="'ALARMS.Open_overlay' | translate"
              [nzType]="'danger'" nz-button
              nz-popconfirm>
        <i nz-icon nzType="desktop"></i>
      </button>
    </div>
    <div class="simulator" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
        <nz-tag *ngIf="rotation.public" [nzColor]="'geekblue'">
          {{'SIMULATOR.COMMUNITY_ROTATIONS.Community_rotation' | translate}}
        </nz-tag>
        <div>{{rotation.getName()}}<span *ngIf="dirty">*</span></div>
        <button (click)="renameRotation(rotation)" [disabled]="(permissionLevel$ | async) < 40"
                [nzTooltipTitle]="'Edit' | translate" nz-button nz-tooltip
                nzShape="circle" nzSize="small">
          <i nz-icon nzType="edit"></i>
        </button>
        <button (click)="changeRotation()" [nzTooltipTitle]="'SIMULATOR.Change_rotation' | translate" nz-button nz-tooltip
                nzShape="circle"
                nzSize="small">
          <i nzIconfont="icon-exchange" nz-icon></i>
        </button>
        <div [nzTooltipTitle]="'SIMULATOR.Safe_mode_tooltip' | translate" fxLayout="row" fxLayoutAlign="flex-start center"
             fxLayoutGap="5px"
             nz-tooltip>
          <div>
            <nz-switch (ngModelChange)="safeMode$.next($event);saveSafeMode($event)"
                       [ngModel]="safeMode$ | async"></nz-switch>
          </div>
          <div>{{'SIMULATOR.Safe_mode' | translate}}</div>
        </div>
      </div>
      <nz-collapse>
        <nz-collapse-panel [(nzActive)]="settings.configurationPanelExpanded"
                           [nzHeader]="'SIMULATOR.Configuration' | translate">
          <div fxLayout="row wrap" fxLayout.lt-md="column" fxLayoutAlign="space-evenly"
               fxLayoutAlign.lt-md="center center"
               fxLayoutGap="16px" fxLayoutGap.lt-md="16px">
            <div fxFlex="1 0 30%" fxLayout="column"
                 tutorialStep="TUTORIAL.SIMULATOR.Character_configuration"
                 tutorialStepIndex="4">
              <h3>{{'SIMULATOR.CONFIGURATION.Job' | translate}}</h3>
              <nz-select (ngModelChange)="customJob$.next($event)" [ngModel]="(stats$ | async)?.jobId"
                         [nzDisabled]="!custom" class="job-selector">
                <nz-option *ngFor="let job of [8,9,10,11,12,13,14,15]" [nzLabel]="job | jobAbbr | i18n"
                           [nzValue]="job" nzCustomContent>
                  <img *ngIf="((job | jobAbbr).en | async) as icon" alt="" class="job-icon" src="https://garlandtools.org/db/images/{{icon}}.png">
                  {{job | jobAbbr | i18n}}
                </nz-option>
              </nz-select>
              <nz-alert *ngIf="(stats$ | async)?.craftsmanship === 0" [nzMessage]="((loggedIn$ | async)?'SIMULATOR.CONFIGURATION.Job_not_configured':
                      'SIMULATOR.CONFIGURATION.Job_not_configured_anonymous') | translate" nzShowIcon
                        nzType="warning">
              </nz-alert>
              <form (ngSubmit)="applyStats()" *ngIf="bonuses$ | async as bonuses" [formGroup]="statsForm"
                    fxLayout="column"
                    fxLayoutAlign="space-between"
                    id="ngForm">
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly center"
                     fxLayoutAlign.lt-md="center center"
                     fxLayoutGap="5px">
                  <nz-form-item>
                    <nz-form-label nzFor="craftsmanship" nzRequired>{{'SIMULATOR.CONFIGURATION.Craftsmanship' |
                      translate}}
                    </nz-form-label>
                    <nz-form-control>
                      <nz-input-group nzAddOnAfter="+{{bonuses.craftsmanship}}">
                        <nz-input-number [nzMin]="0" class="input-number-with-addon" formControlName="craftsmanship"
                                         id="craftsmanship"></nz-input-number>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzFor="control" nzRequired>{{'SIMULATOR.CONFIGURATION.Control' | translate}}
                    </nz-form-label>
                    <nz-form-control>
                      <nz-input-group nzAddOnAfter="+{{bonuses.control}}">
                        <nz-input-number [nzMin]="0" class="input-number-with-addon" formControlName="control"
                                         id="control"></nz-input-number>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-label nzFor="cp" nzRequired>{{'SIMULATOR.Cp_amount' | translate}}</nz-form-label>
                    <nz-form-control>
                      <nz-input-group nzAddOnAfter="+{{bonuses.cp}}">
                        <nz-input-number [nzMin]="0" class="input-number-with-addon" formControlName="cp"
                                         id="cp"></nz-input-number>
                      </nz-input-group>
                    </nz-form-control>
                  </nz-form-item>
                </div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-evenly center"
                     fxLayoutAlign.lt-md="center center"
                     fxLayoutGap="5px">
                  <nz-form-item>
                    <nz-form-label nzFor="level" nzRequired>{{'SIMULATOR.Level' | translate}}</nz-form-label>
                    <nz-form-control>
                      <nz-input-number [nzMin]="0" formControlName="level" id="level"></nz-input-number>
                    </nz-form-control>
                  </nz-form-item>
                  <nz-form-item>
                    <nz-form-control>
                      <label (click)="toggleSpecialist()" formControlName="specialist"
                             id="specialist"
                             nz-checkbox>{{'SIMULATOR.CONFIGURATION.Specialist'
                        | translate}}</label>
                    </nz-form-control>
                  </nz-form-item>
                </div>
              </form>
              <div class="apply" fxLayout="column" fxLayoutGap="5px">
                <div>
                  <button form="ngForm" nz-button nzBlock nzType="primary" type="submit">
                    {{'SIMULATOR.CONFIGURATION.Apply_stats' | translate}}
                  </button>
                </div>
                <div>
                  <button (click)="saveSet()" *ngIf="loggedIn$ | async" [disabled]="savedSet" nz-button nzBlock>
                    {{'SIMULATOR.CONFIGURATION.Save_on_profile' | translate}}
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!custom && hqIngredientsData$ | async as hqIngredientsData; else hqInput" class="ingredients"
                 fxFlex="1 1 30%" fxLayout="column"
                 tutorialStep="TUTORIAL.SIMULATOR.HQ_ingredients"
                 tutorialStepIndex="5">
              <h3>
                {{'SIMULATOR.CONFIGURATION.Ingredients' | translate}}
                <span *ngIf="startingQuality$ | async as startingQuality">({{startingQuality | floor}})</span>
              </h3>
              <nz-list [nzDataSource]="hqIngredientsData"
                       [nzRenderItem]="hqIngredientTemplate">
                <ng-template #hqIngredientTemplate let-ingredient>
                  <nz-list-item [nzContent]="content">
                    <nz-list-item-meta [nzDescription]="ingredient.quality | floor"
                                       [nzTitle]="title"></nz-list-item-meta>
                    <ng-template #title>
                      <span (clipboardCopySuccess)="nameCopied('Item_name_copied', {itemname: $event.content})"
                            [clipboard]="ingredient.id | itemName | i18n"
                            class="hq-ingredient-name">{{ingredient.id | itemName | i18n}}</span>
                    </ng-template>
                    <ng-template #content>
                      <div>
                        <nz-form-control>
                          <nz-input-group nzAddOnAfter="/ {{ingredient.max}}">
                            <nz-input-number [(ngModel)]="ingredient.amount" [nzMax]="ingredient.max"
                                             [nzMin]="0"
                                             class="input-number-with-addon"></nz-input-number>
                          </nz-input-group>
                        </nz-form-control>
                      </div>
                    </ng-template>
                  </nz-list-item>
                </ng-template>
              </nz-list>
              <div class="apply">
                <button (click)="hqIngredients = hqIngredientsData" nz-button nzBlock nzType="primary">
                  {{'SIMULATOR.CONFIGURATION.Apply_ingredients' | translate}}
                </button>
              </div>
            </div>
            <ng-template #hqInput>
              <div *ngIf="simulation$ | async as simulation" fxFlex="1 1 30%">
                <h3>{{'SIMULATOR.CONFIGURATION.Starting_quality' | translate}}</h3>
                <nz-form-control class="hq-input">
                  <nz-input-group nzAddOnAfter="/ {{simulation.recipe.quality}}">
                    <nz-input-number (ngModelChange)="forcedStartingQuality$.next($event)"
                                     [ngModel]="forcedStartingQuality$ | async"
                                     [nzMax]="simulation.recipe.quality"
                                     [nzMin]="0"
                                     class="input-number-with-addon"></nz-input-number>
                  </nz-input-group>
                </nz-form-control>
              </div>
            </ng-template>
            <div *ngIf="crafterStats$ | async as crafterStats" fxFlex="1 0 30%" fxLayout="column"
                 tutorialStep="TUTORIAL.SIMULATOR.Consumables"
                 tutorialStepIndex="6">

              <h3>{{'SIMULATOR.CONFIGURATION.Consumables' | translate}} <b *ngIf="dirtyConsumables" nz-tooltip
                                                                           [nzTooltipTitle]="'COMMON.Dirty_changes' | translate">*</b></h3>
              <div>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" fxLayoutGap="5px">
                  <div class="consumable-col" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="10px">
                    <nz-select [(ngModel)]="selectedFood" (ngModelChange)="dirtyConsumables = true"
                               [nzPlaceHolder]="'SIMULATOR.CONFIGURATION.Food' | translate"
                               nzAllowClear nzShowSearch>
                      <nz-option *ngFor="let food of foods" [nzValue]="food"
                                 nzLabel="{{food.hq?'HQ':''}} {{food.itemId | itemName | i18n}} ">
                      </nz-option>
                    </nz-select>
                    <div *ngIf="selectedFood !== undefined && selectedFood !== null; else noFood">
                      <div>
                        <b>{{selectedFood.itemId | itemName | i18n}}</b>
                        <img *ngIf="selectedFood.hq" alt="(HQ)" src="./assets/icons/HQ.png">
                      </div>
                      <div *ngFor="let bonus of selectedFood.bonuses">
                        {{('SIMULATOR.CONFIGURATION.STATS.' + bonus.type) | translate}}: {{(bonus.value * 100) | floor}}
                        %
                        ({{bonus.max}})
                      </div>
                    </div>
                    <ng-template #noFood>
                      <div class="nothing-selected">
                        {{'SIMULATOR.CONFIGURATION.No_food' | translate}}
                      </div>
                    </ng-template>
                  </div>
                  <div class="consumable-col" fxFlex="1 1 auto" fxLayout="column" fxLayoutGap="10px">
                    <nz-select [(ngModel)]="selectedMedicine" (ngModelChange)="dirtyConsumables = true"
                               [nzPlaceHolder]="'SIMULATOR.CONFIGURATION.Medicine' | translate"
                               nzAllowClear nzShowSearch>
                      <nz-option *ngFor="let medicine of medicines" [nzValue]="medicine"
                                 nzLabel=" {{medicine.hq?'HQ':''}} {{medicine.itemId | itemName | i18n}}">
                      </nz-option>
                    </nz-select>
                    <div *ngIf="selectedMedicine !== undefined && selectedMedicine !== null; else noMedicine">
                      <div>
                        <b>{{selectedMedicine.itemId | itemName | i18n}}</b>
                        <img *ngIf="selectedMedicine.hq" alt="(HQ)" src="./assets/icons/HQ.png">
                      </div>
                      <div *ngFor="let bonus of selectedMedicine.bonuses">
                        {{('SIMULATOR.CONFIGURATION.STATS.' + bonus.type) | translate}}: {{bonus.value * 100}}%
                        ({{bonus.max}})
                      </div>
                    </div>
                    <ng-template #noMedicine>
                      <div class="nothing-selected">
                        {{'SIMULATOR.CONFIGURATION.No_medicine' | translate}}
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div class="consumable-col" fxLayout="column" fxLayoutGap="10px">
                  <nz-select [(ngModel)]="selectedFreeCompanyActions" (ngModelChange)="dirtyConsumables = true"
                             [nzPlaceHolder]="'SIMULATOR.CONFIGURATION.Action' | translate"
                             nzAllowClear nzMode="multiple">
                    <nz-option-group *ngFor="let type of ['Control', 'Craftsmanship']"
                                     [nzLabel]="('SIMULATOR.CONFIGURATION.'+type) | translate">
                      <nz-option *ngFor="let action of getFreeCompanyActions(type)"
                                 [nzDisabled]="isFreeCompanyActionOptionDisabled(type, action.actionId)"
                                 [nzLabel]="action.actionId | freeCompanyActionName | i18n"
                                 [nzValue]="action"></nz-option>
                    </nz-option-group>
                  </nz-select>
                  <div *ngIf="selectedFreeCompanyActions.length > 0; else noFcBuffs">
                    <div *ngFor="let action of selectedFreeCompanyActions">
                      <div>
                        <b>{{action.actionId | freeCompanyActionName | i18n}}</b>
                      </div>
                      <div>
                        {{('SIMULATOR.CONFIGURATION.STATS.' + action.type) | translate}}: {{action.value}}
                      </div>
                    </div>
                  </div>
                  <ng-template #noFcBuffs>
                    <div class="nothing-selected">
                      {{'SIMULATOR.CONFIGURATION.No_fc_buffs' | translate}}
                    </div>
                  </ng-template>
                </div>
              </div>
              <div class="apply" fxFlexAlign="flex-end flex-end" fxLayout="column" fxLayoutGap="5px">
                <div>
                  <button
                    (click)="applyConsumables(crafterStats);this.dirty = true;dirtyFacade.removeEntry('simulator', dirtyScope.PAGE);"
                    nz-button nzBlock
                    [nzType]="dirtyConsumables ? 'primary' : 'default'">
                    {{'SIMULATOR.CONFIGURATION.Apply_foods' | translate}}
                  </button>
                </div>
                <div>
                  <button (click)="saveDefaultConsumables()" nz-button nzBlock>
                    {{'SIMULATOR.CONFIGURATION.Save_consumables' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nz-collapse-panel>
      </nz-collapse>

      <div *ngIf="result$ | async as resultData" fxLayout="column" fxLayoutGap="10px">
        <app-simulation-result [result]="resultData"
                               [itemId]="itemId"
                               [progressPer100]="progressPer100$ | async"
                               [qualityPer100]="qualityPer100$ | async"
                               [thresholds]="thresholds"
                               [report]="report$ | async"
                               (snapshotStepChange)="snapshotStep$.next($event)"
                               (snapshotModeChange)="snapshotMode = $event"
                               (changeRecipe)="changeRecipe(rotation)"
                               [custom]="custom">
        </app-simulation-result>

        <nz-card [class.mobile-buffs]="false | ifMobile: true">
          <div class="buffs-container" fxLayout="row" fxLayoutAlign="flex-start center">
            <div *ngFor="let buff of resultData.simulation.buffs" class="buff-container">
              <span *ngIf="buff.stacks > 0" class="stacks">{{buff.stacks}}</span>
              <img [src]="getBuffIcon(buff)" alt="" class="buff-icon">
              <span *ngIf="buff.duration.toString() !== 'Infinity'" class="duration">{{buff.duration}}</span>
            </div>
          </div>
        </nz-card>

        <nz-card [class.failed]="actionFailed" [class.success]="resultData.simulation.success" class="actions-card">
          <i *ngIf="dirty" [nzTooltipTitle]="'SIMULATOR.Unsaved_changes' | translate" [twoToneColor]="'#aa9400'"
             class="warning-icon" nz-icon
             nz-tooltip nzType="exclamation-circle" theme="twotone"></i>
          <div class="actions-container" fxLayout="row wrap" cdkDropList cdkDropListOrientation="horizontal"
               (cdkDropListDropped)="actionDrop($event)" id="action-results">
            <div *ngFor="let step of resultData.steps;let i = index; trackBy: trackByAction"
                 [cdkDragData]="step.action" [cdkDragDisabled]="!step.action.canBeMoved(i)"
                 class="action-container rotation-action" cdkDrag>
              <app-action (actionclick)="removeAction(i)"
                          tutorialStep="TUTORIAL.SIMULATOR.Right_click_condition"
                          tutorialStepIndex="6"
                          (stepstate)="setState(i, $event)"
                          (failChange)="setFail(i, $event)"
                          [action]="step.action"
                          [cpCost]="step.cpDifference"
                          [disabled]="!step.success || step.skipped"
                          [failed]="!step.success"
                          [tooltipDisabled]="step.action.getIds()[0] < 0"
                          [ignoreDisabled]="true"
                          [showStateMenu]="true"
                          [simulation]="resultData.simulation"
                          [state]="stepStates$.value[i] || 1"></app-action>
            </div>
          </div>
          <ng-container *ngIf="resultData.failCause !== undefined">
            <div [ngSwitch]="resultData.failCause" class="fail-reason">
              <ng-container *ngSwitchCase="'MISSING_STATS_REQUIREMENT'">
                {{('SIMULATOR.FAIL_CAUSE.' + resultData.failCause) | translate}} ({{resultData.simulation.recipe.craftsmanshipReq}}
                /{{resultData.simulation.recipe.controlReq}})
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{('SIMULATOR.FAIL_CAUSE.' + resultData.failCause) | translate}}
              </ng-container>
            </div>
          </ng-container>
        </nz-card>

        <nz-card [class.mobile-actions]="false | ifMobile: true">
          <div class="actions-card-content">
            <div class="detailed-toggle">
              <nz-switch [(ngModel)]="settings.detailedSimulatorActions"
                         [nzUnCheckedChildren]="'SIMULATOR.Compact' | translate"
                         [nzCheckedChildren]="'SIMULATOR.Detailed' | translate"></nz-switch>
            </div>
            <div class="category-row">
              <div>
                <h4>{{'SIMULATOR.CATEGORY.Progression' | translate}}</h4>
                <div cdkDropList [cdkDropListConnectedTo]="['action-results']" cdkDropListSortingDisabled fxLayout="row wrap"
                     [fxLayoutGap]="settings.detailedSimulatorActions ? '10px' : '5px'"
                     fxLayoutAlign="flex-start center" class="actions-row">
                  <ng-container *ngFor="let action of getProgressActions()">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                      <app-action (actionclick)="addAction(action)"
                                  cdkDrag
                                  (cdkDragEnded)="tooltipsDisabled = false"
                                  (cdkDragStarted)="tooltipsDisabled = true"
                                  [cdkDragData]="action"
                                  [cdkDragStartDelay]="50"
                                  [cdkDragDisabled]="snapshotMode"
                                  [action]="action"
                                  [disabled]="resultData.steps.length > 0 && !action.canBeUsed(resultData.simulation, true) || snapshotMode"
                                  [notEnoughCp]="action.getBaseCPCost(resultData.simulation) > resultData.simulation.availableCP"
                                  [safe]="!safeMode$.value || action.getSuccessRate(resultData.simulation) >= 100"
                                  [simulation]="resultData.simulation"
                                  [tooltipDisabled]="tooltipsDisabled"></app-action>
                      <div *ngIf="settings.detailedSimulatorActions">
                        <div class="action-name">{{action.getId(resultData.simulation.crafterStats.jobId) | actionName | i18n}}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div>
                <h4>{{'SIMULATOR.CATEGORY.Quality' | translate}}</h4>
                <div cdkDropList [cdkDropListConnectedTo]="['action-results']" cdkDropListSortingDisabled fxLayout="row wrap" fxLayoutGap="5px"
                     fxLayoutAlign="flex-start center" class="actions-row">
                  <ng-container *ngFor="let action of getQualityActions()">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                      <app-action (actionclick)="addAction(action)"
                                  cdkDrag
                                  (cdkDragEnded)="tooltipsDisabled = false"
                                  (cdkDragStarted)="tooltipsDisabled = true"
                                  [cdkDragData]="action"
                                  [cdkDragStartDelay]="50"
                                  [cdkDragDisabled]="snapshotMode"
                                  [action]="action"
                                  [disabled]="resultData.steps.length > 0 && !action.canBeUsed(resultData.simulation, true) || snapshotMode"
                                  [notEnoughCp]="action.getBaseCPCost(resultData.simulation) > resultData.simulation.availableCP"
                                  [safe]="!safeMode$.value || action.getSuccessRate(resultData.simulation) >= 100"
                                  [simulation]="resultData.simulation"
                                  [tooltipDisabled]="tooltipsDisabled"></app-action>
                      <div *ngIf="settings.detailedSimulatorActions">
                        <div class="action-name">{{action.getId(resultData.simulation.crafterStats.jobId) | actionName | i18n}}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="category-row">
              <div>
                <h4>{{'SIMULATOR.CATEGORY.Buff' | translate}}</h4>
                <div cdkDropList [cdkDropListConnectedTo]="['action-results']" cdkDropListSortingDisabled fxLayout="row wrap" fxLayoutGap="5px"
                     fxLayoutAlign="flex-start center" class="actions-row">
                  <ng-container *ngFor="let action of getBuffActions()">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                      <app-action (actionclick)="addAction(action)"
                                  cdkDrag
                                  (cdkDragEnded)="tooltipsDisabled = false"
                                  (cdkDragStarted)="tooltipsDisabled = true"
                                  [cdkDragData]="action"
                                  [cdkDragStartDelay]="50"
                                  [cdkDragDisabled]="snapshotMode"
                                  [action]="action"
                                  [disabled]="resultData.steps.length > 0 && !action.canBeUsed(resultData.simulation, true) || snapshotMode"
                                  [notEnoughCp]="action.getBaseCPCost(resultData.simulation) > resultData.simulation.availableCP"
                                  [safe]="!safeMode$.value || action.getSuccessRate(resultData.simulation) >= 100"
                                  [simulation]="resultData.simulation"
                                  [tooltipDisabled]="tooltipsDisabled"></app-action>
                      <div *ngIf="settings.detailedSimulatorActions">
                        <div class="action-name">{{action.getId(resultData.simulation.crafterStats.jobId) | actionName | i18n}}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div>
                <h4>{{'SIMULATOR.CATEGORY.Repair' | translate}}</h4>

                <div cdkDropList [cdkDropListConnectedTo]="['action-results']" cdkDropListSortingDisabled fxLayout="row wrap" fxLayoutGap="5px"
                     fxLayoutAlign="flex-start center" class="actions-row">

                  <ng-container *ngFor="let action of getRepairActions()">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                      <app-action (actionclick)="addAction(action)"
                                  cdkDrag
                                  (cdkDragEnded)="tooltipsDisabled = false"
                                  (cdkDragStarted)="tooltipsDisabled = true"
                                  [cdkDragData]="action"
                                  [cdkDragStartDelay]="50"
                                  [cdkDragDisabled]="snapshotMode"
                                  [action]="action"
                                  [disabled]="resultData.steps.length > 0 && !action.canBeUsed(resultData.simulation, true) || snapshotMode"
                                  [notEnoughCp]="action.getBaseCPCost(resultData.simulation) > resultData.simulation.availableCP"
                                  [safe]="!safeMode$.value || action.getSuccessRate(resultData.simulation) >= 100"
                                  [simulation]="resultData.simulation"
                                  [tooltipDisabled]="tooltipsDisabled"></app-action>
                      <div *ngIf="settings.detailedSimulatorActions">
                        <div class="action-name">{{action.getId(resultData.simulation.crafterStats.jobId) | actionName | i18n}}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div>
                <h4>{{'SIMULATOR.CATEGORY.Other' | translate}}</h4>
                <div cdkDropList [cdkDropListConnectedTo]="['action-results']" cdkDropListSortingDisabled fxLayout="row wrap" fxLayoutGap="5px"
                     fxLayoutAlign="flex-start center" class="actions-row">

                  <ng-container *ngFor="let action of getOtherActions()">
                    <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                      <app-action (actionclick)="addAction(action)"
                                  cdkDrag
                                  (cdkDragEnded)="tooltipsDisabled = false"
                                  (cdkDragStarted)="tooltipsDisabled = true"
                                  [cdkDragData]="action"
                                  [cdkDragStartDelay]="50"
                                  [cdkDragDisabled]="snapshotMode"
                                  [action]="action"
                                  [disabled]="resultData.steps.length > 0 && !action.canBeUsed(resultData.simulation, true) || snapshotMode"
                                  [notEnoughCp]="action.getBaseCPCost(resultData.simulation) > resultData.simulation.availableCP"
                                  [safe]="!safeMode$.value || action.getSuccessRate(resultData.simulation) >= 100"
                                  [simulation]="resultData.simulation"
                                  [tooltipDisabled]="tooltipsDisabled"></app-action>
                      <div *ngIf="settings.detailedSimulatorActions">
                        <div class="action-name">{{action.getId(resultData.simulation.crafterStats.jobId) | actionName | i18n}}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </nz-card>
      </div>
    </div>
  </div>
  <ng-template #notFound>
    <app-fullpage-message>
      {{'SIMULATOR.Rotation_not_found' | translate}}
    </app-fullpage-message>
  </ng-template>
</ng-container>
