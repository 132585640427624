export enum ProbeSource {
  UNKNOWN,
  GATHERING,
  CRAFTING,
  FISHING,
  VENDOR,
  TELEPORT,
  MARKETBOARD,
  RETAINER
}
