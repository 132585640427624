// Source: https://github.com/SapphireServer/Sapphire/blob/develop/src/world/Event/EventHandler.h
export enum EventHandlerType {
  Quest = 0x0001,
  Warp = 0x0002,
  GatheringPoint = 0x0003,
  Shop = 0x0004,
  Aetheryte = 0x0005,
  GuildLeveAssignment = 0x0006,
  DefaultTalk = 0x0009,
  Craft = 0x000A,
  CustomTalk = 0x000B,
  CompanyLeveOfficer = 0x000C,
  CraftLeve = 0x000E,
  GimmickAccessor = 0x000F,
  GimmickBill = 0x0010,
  GimmickRect = 0x0011,
  ChocoboTaxiStand = 0x0012,
  Opening = 0x0013,
  ExitRange = 0x0014,
  Fishing = 0x0015,
  GCShop = 0x0016,
  GuildOrderGuide = 0x0017,
  GuildOrderOfficer = 0x0018,
  ContentNpc = 0x0019,
  Story = 0x001A,
  SpecialShop = 0x001B,
  BahamutGuide = 0x001C,
  HousingAethernet = 0x001E,
  FcTalk = 0x001F,
  ICDirector = 0x8003,
  QuestBattleDirector = 0x8006,
}
