export const airshipVoyages = [
  {
    "id": 1,
    "en": "Sea of Clouds 02",
    "de": "Wolkenmeer 2",
    "ja": "雲海02",
    "fr": "Nuages 2"
  },
  {
    "id": 2,
    "en": "Sea of Clouds 03",
    "de": "Wolkenmeer 3",
    "ja": "雲海03",
    "fr": "Nuages 3"
  },
  {
    "id": 3,
    "en": "Sea of Clouds 04",
    "de": "Wolkenmeer 4",
    "ja": "雲海04",
    "fr": "Nuages 4"
  },
  {
    "id": 4,
    "en": "Sea of Clouds 05",
    "de": "Wolkenmeer 5",
    "ja": "雲海05",
    "fr": "Nuages 5"
  },
  {
    "id": 5,
    "en": "Sea of Clouds 06",
    "de": "Wolkenmeer 6",
    "ja": "雲海06",
    "fr": "Nuages 6"
  },
  {
    "id": 6,
    "en": "Sea of Clouds 07",
    "de": "Wolkenmeer 7",
    "ja": "雲海07",
    "fr": "Nuages 7"
  },
  {
    "id": 7,
    "en": "Sea of Clouds 08",
    "de": "Wolkenmeer 8",
    "ja": "雲海08",
    "fr": "Nuages 8"
  },
  {
    "id": 8,
    "en": "Sea of Clouds 09",
    "de": "Wolkenmeer 9",
    "ja": "雲海09",
    "fr": "Nuages 9"
  },
  {
    "id": 9,
    "en": "Sea of Clouds 10",
    "de": "Wolkenmeer 10",
    "ja": "雲海10",
    "fr": "Nuages 10"
  },
  {
    "id": 10,
    "en": "Sea of Clouds 11",
    "de": "Wolkenmeer 11",
    "ja": "雲海11",
    "fr": "Nuages 11"
  },
  {
    "id": 11,
    "en": "Sea of Clouds 12",
    "de": "Wolkenmeer 12",
    "ja": "雲海12",
    "fr": "Nuages 12"
  },
  {
    "id": 12,
    "en": "Sea of Clouds 13",
    "de": "Wolkenmeer 13",
    "ja": "雲海13",
    "fr": "Nuages 13"
  },
  {
    "id": 13,
    "en": "Sea of Clouds 14",
    "de": "Wolkenmeer 14",
    "ja": "雲海14",
    "fr": "Nuages 14"
  },
  {
    "id": 14,
    "en": "Sea of Clouds 15",
    "de": "Wolkenmeer 15",
    "ja": "雲海15",
    "fr": "Nuages 15"
  },
  {
    "id": 15,
    "en": "Sea of Clouds 16",
    "de": "Wolkenmeer 16",
    "ja": "雲海16",
    "fr": "Nuages 16"
  },
  {
    "id": 16,
    "en": "Sea of Clouds 17",
    "de": "Wolkenmeer 17",
    "ja": "雲海17",
    "fr": "Nuages 17"
  },
  {
    "id": 17,
    "en": "Sea of Clouds 18",
    "de": "Wolkenmeer 18",
    "ja": "雲海18",
    "fr": "Nuages 18"
  },
  {
    "id": 18,
    "en": "Sea of Clouds 19",
    "de": "Wolkenmeer 19",
    "ja": "雲海19",
    "fr": "Nuages 19"
  },
  {
    "id": 19,
    "en": "Sea of Clouds 20",
    "de": "Wolkenmeer 20",
    "ja": "雲海20",
    "fr": "Nuages 20"
  },
  {
    "id": 20,
    "en": "Sea of Clouds 21",
    "de": "Wolkenmeer 21",
    "ja": "雲海21",
    "fr": "Nuages 21"
  },
  {
    "id": 21,
    "en": "Sea of Clouds 22",
    "de": "Wolkenmeer 22",
    "ja": "雲海22",
    "fr": "Nuages 22"
  },
  {
    "id": 22,
    "en": "The Diadem (Hard)",
    "de": "Diadem (schwer)",
    "ja": "雲海探索 ディアデム諸島 (Hard)への航路",
    "fr": "Diadème (brutal)"
  },
  {
    "id": 23,
    "en": "Sea of Clouds 23",
    "de": "Wolkenmeer 23",
    "ja": "雲海23",
    "fr": "Nuages 23"
  },
  {
    "id": 24,
    "en": "Sea of Clouds 24",
    "de": "Wolkenmeer 24",
    "ja": "雲海24",
    "fr": "Nuages 24"
  },
  {
    "id": 25,
    "en": "",
    "de": "",
    "ja": "",
    "fr": ""
  },
  {
    "id": 127,
    "en": "",
    "de": "",
    "ja": "",
    "fr": ""
  },
  {
    "id": 128,
    "en": "",
    "de": "",
    "ja": "",
    "fr": ""
  }
];