<nz-input-group [nzAddOnAfter]="suffix">
  <input #searchInput (keyup)="!settings.disableSearchDebounce?query$.next(searchInput.value):null"
         (keydown.enter)="query$.next(searchInput.value)"
         (ngModelChange)="!settings.disableSearchDebounce?query$.next($event):null"
         [ngModel]="query$.value" nz-input nzAutoFocus
         placeholder="{{'ITEMS.Item_name' | translate}}" type="text">
  <ng-template #suffix>
    <label nz-checkbox [(ngModel)]="onlyCraftable" (ngModelChange)="settings.onlyRecipesInPicker = $event">{{'ITEMS.Only_craftable' | translate}}</label>
  </ng-template>
</nz-input-group>
{{'ITEMS.Want_filters' | translate}}
<a routerLink="/search" (click)="close()">
  {{'ITEMS.Open_search' | translate}}
</a>
<ng-container *ngIf="results$ | async as results">
  <nz-list [nzDataSource]="results" [nzLoading]="loading" [nzRenderItem]="itemTemplate">
    <ng-template #itemTemplate let-result>
      <nz-list-item [nzContent]="content">
        <nz-list-item-meta [nzAvatar]="avatar"
                           [nzDescription]="result.recipe !== undefined?description:null"
                           [nzTitle]="result.isCustom ? (result.itemId | customItemName | async) : (result.itemId | itemName | i18n)">
          <ng-template #avatar>
            <div class="item-icon-container">
              <label nz-checkbox [(ngModel)]="result.selected" *ngIf="multi"></label>
              <app-item-icon [icon]="result.icon" [itemId]="result.itemId" [width]="32"></app-item-icon>
            </div>
          </ng-template>
          <ng-template #description>
            <img alt="{{getJob(result.recipe.job)?.abbreviation}}"
                 *ngIf="result.recipe?.job > 1"
                 class="crafted-by"
                 src="https://www.garlandtools.org/db/images/{{getJob(result.recipe.job)?.abbreviation}}.png">
            {{result.recipe.lvl}}
            {{getStars(result.recipe.stars)}}
          </ng-template>
        </nz-list-item-meta>
        <ng-template #content>
          <div fxLayout="row" fxLayoutGap="5px">
            <nz-input-group *ngIf="!hideAmount" [nzAddOnBefore]="null | ifRegions:[Region.China, Region.Korea]:(result.recipe !== undefined?addCrafts:null)"
                            [nzCompact]="true">
              <input [(ngModel)]="result.amount" class="amount-input" nz-input type="number">
              <ng-template #addCrafts>
                <label [(ngModel)]="result.addCrafts" nz-checkbox>
                  {{'Collectable_flag' | translate}}
                </label>
              </ng-template>
            </nz-input-group>
            <button (click)="close(result)" [nzShape]="'circle'" [nzType]="'primary'" nz-button>
              <i nz-icon nzType="check"></i>
            </button>
          </div>
        </ng-template>
      </nz-list-item>
    </ng-template>
  </nz-list>
  <button nz-button nzBlock (click)="pickMulti(results)" [disabled]="nothingSelected(results)"
          *ngIf="multi">{{'LISTS.Add_selection_to_list' | translate}}</button>
</ng-container>
