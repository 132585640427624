export const reductions = {
  "12936": [
    5214,
    5218,
    12968,
    12971
  ],
  "12937": [
    12966,
    12967,
    12969,
    12972
  ],
  "12938": [
    5220,
    5224
  ],
  "12939": [
    12970,
    12973
  ],
  "12940": [
    12831,
    12833
  ],
  "15648": [
    15948,
    15949
  ],
  "20013": [
    20012,
    20011,
    20180
  ],
  "20014": [
    20009,
    19916,
    20181
  ],
  "20015": [
    20010
  ],
  "20016": [
    19937
  ],
  "20017": [
    20024
  ],
  "23182": [
    23220,
    23221
  ],
  "27410": [],
  "27411": [
    5267,
    13,
    5817
  ],
  "27412": [
    5267,
    13
  ],
  "27413": [
    5267,
    13
  ],
  "27415": [
    5267,
    13
  ],
  "27416": [],
  "27417": [
    5267,
    13,
    5814
  ],
  "27418": [
    5267,
    13
  ],
  "27419": [],
  "27420": [
    5267,
    13
  ],
  "27421": [
    5267,
    13,
    5815
  ],
  "27422": [
    5267,
    13,
    5817
  ],
  "27423": [
    5267,
    13,
    5815
  ],
  "27424": [
    5267,
    13,
    5815
  ],
  "27425": [
    5267,
    13,
    5814
  ],
  "27426": [
    5267,
    13
  ],
  "27427": [
    5267,
    13,
    5820
  ],
  "27428": [
    5267,
    13,
    5817
  ],
  "27429": [
    5267,
    13,
    5817
  ],
  "27430": [
    5267,
    13,
    5816
  ],
  "27431": [
    5267,
    13,
    5817,
    28062
  ],
  "27433": [
    5267,
    13,
    5817
  ],
  "27436": [
    5267,
    13
  ],
  "27437": [
    5267,
    13
  ],
  "27438": [
    5267,
    13
  ],
  "27439": [
    5267,
    13,
    5816
  ],
  "27440": [
    13,
    5818
  ],
  "27441": [
    5267,
    13
  ],
  "27442": [
    5267,
    13,
    5817
  ],
  "27445": [
    13,
    28062
  ],
  "27446": [
    5267,
    13,
    5816,
    28062
  ],
  "27447": [
    5267,
    13,
    5816,
    28062
  ],
  "27448": [
    5267,
    13,
    5818,
    28062
  ],
  "27449": [
    5267,
    13,
    5818,
    28062
  ],
  "27450": [
    5267,
    13
  ],
  "27452": [
    13,
    28062
  ],
  "27453": [
    13,
    5818
  ],
  "27454": [
    5267,
    13
  ],
  "27455": [
    13,
    5816
  ],
  "27458": [
    5267,
    13,
    28062
  ],
  "27459": [
    5267,
    13,
    5816
  ],
  "27460": [
    5267,
    13,
    5819
  ],
  "27462": [
    5267,
    13
  ],
  "27463": [
    5267,
    13,
    5815
  ],
  "27464": [
    5267,
    13
  ],
  "27465": [
    5267,
    13,
    5817
  ],
  "27466": [
    5267,
    13,
    5818
  ],
  "27467": [
    5267,
    13
  ],
  "27468": [
    13,
    28062
  ],
  "27469": [
    5267,
    13
  ],
  "27470": [
    5267,
    13,
    5817,
    28062
  ],
  "27471": [
    5267,
    13,
    5815
  ],
  "27472": [
    5267,
    13,
    5819,
    28062
  ],
  "27473": [
    5267,
    13
  ],
  "27474": [
    5267,
    13
  ],
  "27475": [
    5267,
    13
  ],
  "27476": [
    5267,
    13
  ],
  "27477": [
    13,
    5815
  ],
  "27478": [
    5267,
    13
  ],
  "27479": [
    5267,
    13,
    5814,
    28062
  ],
  "27480": [
    5267,
    13
  ],
  "27482": [
    5267,
    13,
    5815
  ],
  "27484": [
    13,
    5815
  ],
  "27485": [
    5267,
    13
  ],
  "27487": [
    5267,
    13,
    5815
  ],
  "27488": [
    5267,
    13
  ],
  "27489": [
    5267,
    13,
    28062
  ],
  "27490": [
    5267,
    13
  ],
  "27492": [
    13,
    28062
  ],
  "27493": [
    5267,
    13
  ],
  "27495": [],
  "27496": [
    5267,
    13
  ],
  "27497": [
    5267,
    13,
    5814,
    28062
  ],
  "27498": [
    5267,
    13,
    5814
  ],
  "27499": [
    5267,
    13,
    5814
  ],
  "27500": [
    5267,
    13
  ],
  "27501": [
    5267,
    13,
    5817
  ],
  "27502": [
    5267,
    13
  ],
  "27503": [
    13,
    5814
  ],
  "27504": [
    5267,
    13
  ],
  "27505": [
    13,
    5818
  ],
  "27509": [
    5267,
    13
  ],
  "27512": [
    13,
    5817
  ],
  "27513": [
    5267,
    13
  ],
  "27514": [
    5267,
    13,
    5819
  ],
  "27516": [
    5267,
    13,
    5817
  ],
  "27517": [
    5267,
    13,
    5820,
    28062
  ],
  "27518": [],
  "27519": [],
  "27520": [
    5267,
    13
  ],
  "27521": [
    5267,
    13
  ],
  "27522": [
    5267,
    13,
    5817
  ],
  "27523": [
    5267,
    13
  ],
  "27524": [
    5267,
    13,
    5820
  ],
  "27525": [
    13,
    5817
  ],
  "27526": [
    5267,
    13,
    5820
  ],
  "27527": [
    5267,
    13
  ],
  "27528": [
    13,
    5817
  ],
  "27529": [
    13,
    5820
  ],
  "27530": [
    5267,
    13
  ],
  "27531": [
    13,
    5820
  ],
  "27532": [
    5267,
    13
  ],
  "27533": [
    5267,
    13,
    28062
  ],
  "27536": [
    13,
    5814,
    28062
  ],
  "27537": [
    5267,
    13,
    5814,
    28062
  ],
  "27538": [
    5267,
    13,
    5816
  ],
  "27539": [
    5267,
    13,
    5819,
    28062
  ],
  "27544": [
    5267,
    13
  ],
  "27545": [
    5267,
    13,
    5820
  ],
  "27546": [
    5267,
    13,
    5815
  ],
  "27547": [
    5267,
    13
  ],
  "27548": [
    13,
    5819
  ],
  "27549": [
    5267,
    13
  ],
  "27550": [
    13,
    5820
  ],
  "27551": [
    5267,
    13,
    5820
  ],
  "27552": [
    5267,
    13,
    5820
  ],
  "27553": [
    5267,
    13
  ],
  "27554": [
    5267,
    13,
    5815,
    28062
  ],
  "27555": [
    13,
    5817
  ],
  "27556": [
    5267,
    13,
    5815
  ],
  "27557": [
    13
  ],
  "27558": [
    5267,
    13
  ],
  "27559": [
    5267,
    13,
    5820
  ],
  "27560": [
    5267,
    13,
    5816
  ],
  "27561": [
    5267,
    13,
    5816,
    28062
  ],
  "27562": [
    5267,
    13
  ],
  "27563": [
    13,
    5815
  ],
  "27564": [
    5267,
    13
  ],
  "27565": [
    5267,
    13
  ],
  "27568": [
    5267,
    13
  ],
  "27569": [
    5267,
    13
  ],
  "27570": [
    5267,
    13,
    5816
  ],
  "27571": [
    5267,
    13,
    5814
  ],
  "27572": [
    5267,
    13
  ],
  "27573": [
    5267,
    13
  ],
  "27574": [
    5267,
    13,
    5818
  ],
  "27575": [],
  "27576": [
    5267,
    13
  ],
  "27577": [
    13,
    5819
  ],
  "27578": [
    5267,
    13
  ],
  "27579": [
    5267,
    13
  ]
};