<p>{{'PACKET_CAPTURE.Install_npcap_description' | translate}}</p>
<nz-divider></nz-divider>
<div fxLayout="row" fxLayoutGap="10px">
  <div>
    <button nz-button nzType="primary" (click)="install()">{{'PACKET_CAPTURE.Install_npcap_button' | translate}}</button>
  </div>
  <div>
    <button nz-button nzType="danger" (click)="shutdownPcap()">{{'PACKET_CAPTURE.Disable_pcap' | translate}}</button>
  </div>
</div>

