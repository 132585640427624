<div *ngIf="mapData$ | async as map" class="map-container">
  <app-db-button *ngIf="!hideDbButton" [id]="mapId" class="db-button" type="map"></app-db-button>
  <img (load)="loaded.emit()" alt="" class="map-image" src="{{map.image}}">
  <img *ngFor="let aetheryte of map.aetherytes" [ngStyle]="getMarkerStyle(map, aetheryte, true)"
       [src]="getIcon(aetheryte.type)" alt="" class="aetheryte"
       nz-tooltip nzTooltipTitle="{{(aetheryte.id | aetheryteName | i18n) || (aetheryte?.nameid | placeName | i18n)}}">
  <div *ngIf="unknownPosition" class="unknown-position">
    {{'No_known_coordinates' | translate}}
  </div>
  <ng-container *ngFor="let marker of markers">
    <ng-container *ngIf="marker.x || marker.y">
      <i *ngIf="!marker.iconType"
         [ngStyle]="getMarkerStyle(map, marker)"
         [nzTooltipTitle]="marker.tooltip || 'X: ' + (marker.x | number: '1.0-1') + ' - Y: ' + (marker.y | number: '1.0-1')"
         [routerLink]="marker.link"
         class="marker material-icons default-marker" nz-tooltip>
        location_on
      </i>
      <img *ngIf="marker.iconType === 'img'" [ngStyle]="getMarkerStyle(map, marker)"
           [nzTooltipTitle]="marker.tooltip || 'X: ' + (marker.x | number: '1.0-1') + ' - Y: ' + (marker.y | number: '1.0-1')"
           [routerLink]="marker.link"
           [src]="marker.iconImg"
           alt=""
           class="marker" nz-tooltip>
      <i *ngIf="marker.iconType === 'ant'" [ngStyle]="getMarkerStyle(map, marker)"
         [nzTooltipTitle]="marker.tooltip || 'X: ' + (marker.x | number: '1.0-1') + ' - Y: ' + (marker.y | number: '1.0-1')"
         [routerLink]="marker.link"
         [type]="marker.nzIcon"
         class="marker"
         nz-icon nz-tooltip></i>
      <i *ngIf="marker.iconType === 'material'"
         [ngStyle]="getMarkerStyle(map, marker)"
         [nzTooltipTitle]="marker.tooltip || 'X: ' + (marker.x | number: '1.0-1') + ' - Y: ' + (marker.y | number: '1.0-1')"
         [routerLink]="marker.link"
         class="marker material-icons" nz-tooltip>
        {{marker.materialIcon}}
      </i>
    </ng-container>
  </ng-container>
</div>
