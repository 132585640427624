<div class="config" fxLayout="column" fxLayoutGap="5px">
  <nz-alert *ngIf="tooManyAactions" [nzMessage]="'SIMULATOR.Too_many_aactions' | translate" nzShowIcon
            nzType="danger"></nz-alert>
  <label [(ngModel)]="addEcho" (ngModelChange)="generateMacros()" nz-checkbox>
    {{'SIMULATOR.Include_sound_end' | translate}}
  </label>
  <label [(ngModel)]="fixedEcho" (ngModelChange)="generateMacros()" [nzDisabled]="!addEcho" nz-checkbox>
    {{'SIMULATOR.Fixed_notification_number' | translate}}
  </label>
  <label [(ngModel)]="breakBeforeByregotsBlessing" (ngModelChange)="generateMacros()" nz-checkbox>
    {{'SIMULATOR.Break_before_byregots_blessing' | translate}}
  </label>
  <label [(ngModel)]="macroLock" (ngModelChange)="generateMacros()" nz-checkbox>
    {{'SIMULATOR.Macrolock' | translate}}
  </label>
  <label [(ngModel)]="addConsumables" (ngModelChange)="generateMacros()" nz-checkbox>
    {{'SIMULATOR.Include_consumables_notification' | translate}}
  </label>
  <div class="fields">
    <nz-form-item *ngIf="addConsumables">
      <nz-form-label>{{'SIMULATOR.Include_consumables_notification_wait_time' | translate}}</nz-form-label>
      <nz-input-number [(ngModel)]="addConsumablesWaitTime" (ngModelChange)="generateMacros()" [nzMax]="100"
                       [nzMin]="0"></nz-input-number>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>{{'SIMULATOR.Starting_echo_number' | translate}}</nz-form-label>
      <nz-input-number [(ngModel)]="echoSeNumber" (ngModelChange)="generateMacros()" [nzDisabled]="!addEcho" [nzMax]="16"
                       [nzMin]="1"></nz-input-number>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>{{'SIMULATOR.Extra_wait' | translate}}</nz-form-label>
      <nz-input-number [(ngModel)]="extraWait" (ngModelChange)="generateMacros()" [nzMax]="100"
                       [nzMin]="0"></nz-input-number>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label>{{'SIMULATOR.Completion_message' | translate}}</nz-form-label>
      <input type="text" nz-input
             [(ngModel)]="macroCompletionMessage"
             (ngModelChange)="generateMacros()">
    </nz-form-item>
  </div>
</div>
<div class="macro">
  <pre *ngFor="let macroFragment of macro" class="macro-fragment">
    <button [clipboard]="getText(macroFragment)" class="copy-macro" nz-button nzShape="circle"
            nzSize="small">
        <i nz-icon nzType="copy"></i>
    </button>
    <span *ngFor="let line of macroFragment" class="macro-line">{{line}}<br></span>
  </pre>
  <span>{{'SIMULATOR.Total_macro_duration' | translate:{ duration: totalDuration } }}</span>
</div>
