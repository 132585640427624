<app-loading-screen *ngIf="!dataLoaded; else appLayout"></app-loading-screen>
<ng-template #appLayout>
  <nz-layout *ngIf="!(hasDesktop$ | async); else desktopOpenedMessage" [class.no-panel-borders]="settings.noPanelBorders"
             class="app-container">
    <nz-layout *ngIf="!overlay">
      <nz-header class="header">
        <a class="logo" routerLink="/" fxLayout="row" fxLayoutAlign="center center" [innerHTML]="titleBreakpoints | widthBreakpoints"></a>
        <div class="language-swap" *ngIf="true | ifMobile: false">
          <nz-select (ngModelChange)="use($event)" [ngModel]="translate.currentLang"
                     fxFlex="0 0 auto"
                     fxLayout="row">
            <nz-option *ngFor="let locale of availableLanguages" [nzLabel]="locale | uppercase"
                       [nzValue]="locale"></nz-option>
          </nz-select>
        </div>
        <div>
          <i nz-icon nzType="question-circle" nzTheme="outline"
             (click)="startTutorial()" class="tutorial-start"
             nz-tooltip [nzTooltipTitle]="'TUTORIAL.Start' | translate"></i>
        </div>
        <div *ngIf="platformService.isDesktop() && newVersionAvailable$ | async" class="update-status">
          <ng-container *ngIf="checkingForUpdate$ | async as updateStatus">
            <div [ngSwitch]="updateStatus">
              <div *ngSwitchCase="UpdaterStatus.UPDATE_AVAILABLE">
                <button (click)="updateDesktopApp()" [nzTooltipTitle]="'UPDATE.New_update_available_desktop' | translate" nz-button nz-tooltip
                        nzTooltipPlacement="bottom"
                        nzShape="circle"
                        nzType="primary"><i nz-icon nzType="download"></i></button>
              </div>
              <div *ngSwitchCase="UpdaterStatus.DOWNLOADING"
                   class="download-spinner"
                   nz-tooltip
                   [nzTooltipTitle]="'UPDATE.Downloading_update' | translate">
                <nz-spin [nzSimple]="true"></nz-spin>
              </div>
              <div *ngSwitchCase="UpdaterStatus.POSSIBLE_ERROR">
                <a *ngIf="updateVersion$ | async as updateVersion"
                   href="https://github.com/ffxiv-teamcraft/ffxiv-teamcraft/releases/download/v{{updateVersion}}/ffxiv-teamcraft-Setup-{{updateVersion}}.exe"
                   target="_blank">
                  <i nz-icon nzType="exclamation-circle"
                     nzTheme="twotone"
                     nzTwotoneColor="#f5222d"
                     nz-tooltip
                     [nzTooltipTitle]="'UPDATE.Possible_update_issue' | translate"></i>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
        <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="center center">
          <app-ad *ngIf="showAd$ | async" [placementId]="adsPlacementBreakpoints | widthBreakpoints"></app-ad>
        </div>
        <ul [nzMode]="'horizontal'" [nzTheme]="'dark'" class="right-menu" nz-menu>
          <ng-container *ngIf="pinnedList$ | async as pinnedList">
            <li *ngIf="pinnedList !== 'none'"
                class="ant-menu-item"
                nzTooltipPlacement="bottom"
                (contextmenu)="$event.preventDefault();listsFacade.unpin()"
                nz-tooltip [nzTooltipTitle]="'LIST.Go_to_pinned' | translate"
                routerLink="/list/{{pinnedList}}">
              <i nz-icon nzType="pushpin" nzTheme="outline"></i>
            </li>
          </ng-container>
          <li (click)="toggleTimeFormat()" class="clock ant-menu-item" fxFlex fxHide.lt-sm fxShow>
            {{time$ | async}}
          </li>
          <li *ngIf="!platformService.isDesktop()" [nzTooltipTitle]="'COMMON.Open_in_desktop' | translate" class="clock ant-menu-item" fxFlex fxHide.lt-sm
              fxShow
              nz-tooltip nzTooltipPlacement="bottom">
            <a (click)="openInApp()">
              <i nzIconfont="icon-openinapp" nz-icon></i>
            </a>
          </li>
          <li *ngIf="platformService.isDesktop()" [nzTooltipTitle]="'COMMON.Open_link' | translate" class="clock ant-menu-item" fxFlex fxHide.lt-sm fxShow
              nz-tooltip nzTooltipPlacement="bottom" (click)="openLink()">
            <i nz-icon nzType="laptop" nzTheme="outline"></i>
          </li>
          <li *ngIf="notifications$ | async as notifications" [nzPopoverContent]="notificationsTemplate" class="ant-menu-item" nz-popover
              nzPopoverPlacement="bottomRight">
            <nz-badge [nzCount]="notifications.length" class="notifications-count">
              <i nz-icon nzType="notification"></i>
            </nz-badge>
            <ng-template #notificationsTemplate>
              <nz-list [nzDataSource]="notifications" [nzNoResult]="noNotifications"
                       [nzRenderItem]="notificationTemplate" class="notifications-list">
                <ng-template #noNotifications>
                  <nz-empty [nzNotFoundContent]="'NOTIFICATIONS.No_notifications' | translate"></nz-empty>
                </ng-template>
                <ng-template #notificationTemplate let-notification>
                  <nz-list-item [nzActions]="[actionOpen, actionClose]">
                    <nz-list-item-meta [nzAvatar]="icon"
                                       [nzDescription]="notification.route.join('/')"
                                       [nzTitle]="notification.content">
                      <ng-template #icon>
                        <i nz-icon nzType="{{notification.icon}}"></i>
                      </ng-template>
                    </nz-list-item-meta>
                  </nz-list-item>
                  <ng-template #actionClose>
                    <i (click)="$event.stopPropagation();deleteNotification(notification)" nz-icon nzType="close"></i>
                  </ng-template>
                  <ng-template #actionOpen>
                    <a (click)="$event.stopPropagation();deleteNotification(notification)"
                       [routerLink]="notification.route">
                      <i nz-icon nzType="select"></i>
                    </a>
                  </ng-template>
                </ng-template>
              </nz-list>
            </ng-template>
          </li>
          <li [class.accent]="!(loggedIn$ | async)" class="user-menu" nz-submenu>
            <span title class="user-menu-title">
              <nz-spin *ngIf="loading$ | async; else authDetails"></nz-spin>
              <ng-template #authDetails>
                <span *ngIf="!(loggedIn$ | async); else userLoggedIn">
                  <nz-avatar [nzText]="'A'">
                  </nz-avatar>
                  {{'COMMON.Anonymous' | translate}}
                </span>
              </ng-template>
              <ng-template #userLoggedIn>
                <div *ngIf="character$ | async as character; else emptyCharacter"
                     tutorialStep="TUTORIAL.APP.Profile" tutorialStepIndex="999" tutorialStepAlign="bottom">
                  <app-user-avatar *ngIf="character.ID > 0; else krAvatar" [flex]="false" [ignoreVerification]="true"
                                   [userId]="userId$ | async"
                                   [width]="32"></app-user-avatar>
                  <ng-template #krAvatar>
                    <nz-avatar [nzText]="character.Name[0]"></nz-avatar>
                  </ng-template>
                  {{character.Name | ifMobile:''}}
                </div>
              </ng-template>
              <ng-template #emptyCharacter>
                <span>
                  <nz-avatar [nzText]="'U'">
                  </nz-avatar>
                  {{'COMMON.Unknown' | translate}}
                </span>
              </ng-template>
            </span>
            <div>
              <a *ngIf="loggedIn$ | async" nz-menu-item routerLink="/profile">
                <i nz-icon nzType="idcard"></i>
                {{'Profile' | translate}}
              </a>
              <a *ngIf="loggedIn$ | async" nz-menu-item routerLink="/teams">
                <i nz-icon nzType="team"></i>
                {{'TEAMS.Title' | translate}}
              </a>
              <a *ngIf="loggedIn$ | async" nz-menu-item routerLink="/favorites">
                <i nz-icon nzType="heart"></i>
                {{'Favorites' | translate}}
              </a>
              <ng-container *ngIf="otherCharacters$ | async as characters">
              <span *ngIf="characters.length > 0" nz-submenu>
                <span title>
                  <i nz-icon nzType="swap" nzTheme="outline"></i>
                  {{'Change_character' | translate}}
                </span>
                <ul>
                  <li nz-menu-item *ngFor="let character of characters" (click)="switchCharacter(character.ID)">
                    {{character.Name}}
                  </li>
                </ul>
              </span>
              </ng-container>
              <a (click)="openSettings()" nz-menu-item>
                <i nz-icon nzType="setting"></i>
                {{'SETTINGS.Title' | translate}}
              </a>
              <ng-container *ngIf="user$ | async as user">
                <a *ngIf="user.patron || user.admin" nz-menu-item routerLink="/custom-links">
                  <i nz-icon nzType="link"></i>
                  {{'CUSTOM_LINKS.Title' | translate}}
                </a>
                <a *ngIf="user.sekrit" nz-menu-item routerLink="/mappy">
                  <i nz-icon nzType="scan"></i>
                  Mappy
                </a>
              </ng-container>
              <span nz-menu-divider></span>
              <a (click)="logOut()" *ngIf="loggedIn$ | async" nz-menu-item>
                <i nz-icon nzType="logout"></i>
                {{'Disconnect' | translate}}
              </a>
              <a (click)="openRegisterPopup()" *ngIf="!(loggedIn$ | async)" nz-menu-item>
                <i nz-icon nzType="form"></i>
                {{'Registration' | translate}}
              </a>
              <a (click)="openLoginPopup()" *ngIf="!(loggedIn$ | async)" nz-menu-item>
                <i nz-icon nzType="login"></i>
                {{'Login' | translate}}
              </a>
            </div>
          </li>
        </ul>
      </nz-header>
    </nz-layout>
    <nz-layout [class.overlay]="overlay" class="content-container">
      <nz-sider (nzCollapsedChange)="settings.compactSidebar = $event"
                *ngIf="!overlay"
                [(nzCollapsed)]="settings.compactSidebar"
                [nzCollapsedWidth]="80 | ifMobile: 0"
                class="sider"
                nzCollapsible>
        <div class="sidebar-container" fxLayout="column" fxLayoutAlign="space-between">
          <div fxLayout="column" fxFlex="1 1 auto" class="menu-categories-container">
            <button class="back-button" (click)="back()" *ngIf="platformService.isDesktop()">
              <i nz-icon nzType="left" nzTheme="outline"></i>
              {{'COMMON.Back' | translate}}
            </button>
            <div class="menu-categories" fxFlex="1 1 auto">
              <app-navigation-sidebar
                [desktop]="platformService.isDesktop()"
                [collapsed]="settings.compactSidebar"
                [mappyEnabled]="mappy.available"
                [version]="version"
                (showPatchNotes)="showPatchNotes()">
              </app-navigation-sidebar>
            </div>

            <div [fxLayout]="settings.compactSidebar?'column':'row'" fxLayoutAlign="center center" fxLayoutGap="5px" class="overlay-buttons"
                 *ngIf="platformService.isDesktop()">
              <button nz-button nzShape="circle" nz-tooltip [nzTooltipTitle]="'ALARMS.Open_alarms_overlay' | translate"
                      (click)="openOverlay('/alarms-overlay')">
                <i nz-icon nzType="bell"></i>
              </button>
              <button nz-button nzShape="circle"
                      *ngIf="ipc.machinaToggle"
                      nz-tooltip [nzTooltipTitle]="'DB.FISH.OVERLAY.Open' | translate"
                      (click)="openOverlay('/fishing-reporter-overlay')" class="button-with-img">
                <img src="./assets/icons/classjob/fisher.png" alt="">
              </button>
              <button nz-button nzShape="circle"
                      *ngIf="ipc.machinaToggle && mappy.available"
                      nz-tooltip nzTooltipTitle="Open mappy overlay"
                      (click)="openOverlay('/mappy-overlay')">
                <i nz-icon nzType="scan" nzTheme="outline"></i>
              </button>
              <button nz-button nzShape="circle"
                      nz-tooltip [nzTooltipTitle]="'LIST_DETAILS.OVERLAY.Open' | translate"
                      (click)="openOverlay('/list-panel-overlay')">
                <i nz-icon nzType="unordered-list" nzTheme="outline"></i>
              </button>
              <button nz-button nzShape="circle"
                      nz-tooltip [nzTooltipTitle]="'ITEM_SEARCH_OVERLAY.Open' | translate"
                      (click)="openOverlay('/item-search-overlay')">
                <i nz-icon nzType="search" nzTheme="outline"></i>
              </button>
            </div>

            <button class="patreon-button" *ngIf="showPatreonButton$ | async" (click)="openSupportPopup()">
              <img
                alt="patreon logo" class="patreon-logo"
                src="https://c5.patreon.com/external/logo/downloads_logomark_color_on_coral.png">
              <br>
              <span *ngIf="settings.compactSidebar">{{'COMMON.Support_us' | translate}}</span>
              <span *ngIf="!settings.compactSidebar">{{'COMMON.Support_us_remove_ads' | translate}}</span>
            </button>
          </div>
        </div>
      </nz-sider>
      <nz-content>
        <div [class.overlay]="overlay" class="page-container">
          <nz-alert *ngIf="(newVersionAvailable$ | async) && !platformService.isDesktop()" [nzMessage]="message" class="new-version-alert"
                    nzBanner
                    nzShowIcon
                    nzType="info">
            <ng-template #message>
              {{'UPDATE.New_update_available' | translate}}
              {{'UPDATE.Web_instructions' | translate}}
            </ng-template>
          </nz-alert>
          <nz-alert *ngIf="ipc.machinaToggle && (pcapOutDated$ | async)"
                    [nzMessage]="'PACKET_CAPTURE.Outdated' | translate"
                    [nzDescription]="'PACKET_CAPTURE.Outdated_description' | translate"
                    class="new-version-alert"
                    nzBanner
                    nzShowIcon
                    nzCloseable
                    nzType="warning">
          </nz-alert>
          <nz-alert (nzOnClose)="settings.hideRegionBanner = true" *ngIf="!settings.hideRegionBanner && suggestedRegion"
                    [nzCloseText]="'Close' | translate"
                    [nzDescription]="description"
                    [nzMessage]="'SUGGESTED_REGION.Banner_title' | translate: { region: ('SETTINGS.REGIONS.' + suggestedRegion | translate) }"
                    class="new-version-alert"
                    nzBanner
                    nzShowIcon
                    nzType="info">
            <ng-template #description>
              {{'SUGGESTED_REGION.Banner_description' | translate}}<br>
              <button (click)="changeToSuggestedRegion()" nz-button>
                {{'SUGGESTED_REGION.Button_label' | translate}}
              </button>
            </ng-template>
          </nz-alert>
          <nz-alert (nzOnClose)="settings.hideMachinaBanner = true"
                    *ngIf="platformService.isDesktop() && !settings.hideMachinaBanner && !ipc.machinaToggle"
                    [nzCloseText]="'Close' | translate"
                    [nzDescription]="description"
                    [nzMessage]="'PACKET_CAPTURE.Banner_title' | translate"
                    class="new-version-alert"
                    nzBanner
                    nzShowIcon
                    nzCloseable
                    nzType="info">
            <ng-template #description>
              {{'PACKET_CAPTURE.Banner_description' | translate}}<br>
              <i>{{'PACKET_CAPTURE.Banner_description_warning' | translate}}</i><br>
              <div fxLayout="row" fxLayoutAlign="flex-start center" fxLayoutGap="5px">
                <button (click)="enablePacketCapture()" nz-button>
                  {{'PACKET_CAPTURE.Button_label' | translate}}
                </button>
                <div>{{'Or' | translate}}</div>
                <a href="https://wiki.ffxivteamcraft.com/advanced-features/packet-capture">{{'COMMON.Read_more_on_wiki' | translate}}</a>
              </div>
            </ng-template>
          </nz-alert>
          <nz-alert
            *ngIf="!firewallRuleApplied && platformService.isDesktop() && ipc.machinaToggle && (possibleMissingFirewallRule$ | async)  && !(pcapOutDated$ | async)"
            class="new-version-alert"
            [nzMessage]="'PACKET_CAPTURE.Possible_missing_firewall_rule' | translate"
            nzBanner nzCloseable nzType="warning" [nzCloseText]="'Close' | translate"
            [nzDescription]="pcapFirewallRuleDescription">
            <ng-template #pcapFirewallRuleDescription>
              {{'PACKET_CAPTURE.Possible_missing_firewall_rule_description' | translate}}<br>
              <i>{{'PACKET_CAPTURE.Possible_missing_firewall_rule_check' | translate}}</i><br>
              <button nz-button (click)="applyFirewallRule()">{{'PACKET_CAPTURE.Add_firewall_rule' | translate}}</button>
            </ng-template>
          </nz-alert>
          <ng-container *ngIf="platformService.isDesktop() && ipc.machinaToggle && !overlay">
            <nz-alert *ngIf="emptyInventory$ | async" [nzDescription]="'PACKET_CAPTURE.Please_zone' | translate"
                      [nzMessage]="'PACKET_CAPTURE.Empty_inventory' | translate"
                      class="new-version-alert"
                      nzBanner
                      nzShowIcon
                      nzType="warning">
            </nz-alert>
            <nz-alert *ngIf="unknownContentId$ | async" [nzDescription]="'PACKET_CAPTURE.Please_sort' | translate"
                      [nzMessage]="'PACKET_CAPTURE.Unknown_character' | translate"
                      class="new-version-alert"
                      nzBanner
                      nzShowIcon
                      nzCloseable
                      nzType="warning">
            </nz-alert>
            <ng-container *ngIf="user$ | async as user">
              <nz-alert *ngIf="user.cid === undefined && (loggedIn$ | async) && settings.enableUniversalisSourcing && !overlay"
                        [nzDescription]="'PACKET_CAPTURE.Please_relog' | translate"
                        [nzMessage]="'PACKET_CAPTURE.No_character_id' | translate"
                        class="new-version-alert"
                        nzBanner
                        nzShowIcon
                        nzType="warning">
              </nz-alert>
            </ng-container>
          </ng-container>
          <nz-spin [nzSize]="'large'" [nzSpinning]="navigating" class="app-container">
            <router-outlet></router-outlet>
          </nz-spin>
        </div>
      </nz-content>
      <nz-sider *ngIf="!overlay"
                [(nzCollapsed)]="collapsedAlarmsBar"
                [nzCollapsedWidth]="0"
                [nzWidth]="230"
                [nzZeroTrigger]="alarmsTrigger"
                class="right-sider"
                nzCollapsible>
        <ng-template #alarmsTrigger>
          <i nz-icon nzType="bell"></i>
        </ng-template>
        <app-alarms-sidebar *ngIf="!(loading$ | async)" appLazyComponent></app-alarms-sidebar>
      </nz-sider>
    </nz-layout>
    <nz-footer *ngIf="settings.getOverlayClockDisplay(ipc.overlayUri)" [fxHide]="!overlay" class="footer" fxShow.lt-sm>
      <span (click)="toggleTimeFormat()" class="timer">{{time$ | async}}</span>
    </nz-footer>
  </nz-layout>
  <ng-template #desktopOpenedMessage>
    <div class="opened-in-desktop" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
      <img alt="FFXIV Teamcraft" src="./assets/logo.png">
      <div class="desktop-message">{{'COMMON.Opened_in_desktop' | translate}}</div>
    </div>
  </ng-template>
</ng-template>
