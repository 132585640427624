<div *ngIf="servers$ | async as availableServers; else loading">
  <nz-form-item>
    <nz-form-label nzFor="serverName" nzRequired>{{'Server_name' | translate}}</nz-form-label>
    <input [formControl]="selectedServer" [nzAutocomplete]="auto" autofocus id="serverName" nz-input type="text">
    <nz-autocomplete #auto>
      <nz-auto-option *ngFor="let server of autoCompleteRows$ | async" [nzValue]="server">
        {{server}}
      </nz-auto-option>
    </nz-autocomplete>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label nzFor="characterName" nzRequired>{{'Character_name' | translate}}
    </nz-form-label>
    <input [formControl]="characterName" id="characterName" nz-input type="text">
  </nz-form-item>
  <button (click)="setKoreanCharacter()" *ngIf="selectedServer.value
   && (selectedServer.value.toString().toLowerCase().indexOf('korean server') > -1 || chineseServers.indexOf(selectedServer.value.toString()) > -1)" nz-button
          nzType="primary">
    {{'Add_manual_character' | translate}}
  </button>
</div>

<div *ngIf="selectedServer.value && selectedServer.value.toString().toLowerCase().indexOf('korean server') === -1">
  <div class="results">
    <nz-list [nzDataSource]="result$ | async"
             [nzItemLayout]="'horizontal'"
             [nzLoading]="loadingResults"
             [nzRenderItem]="characterTemplate">
      <ng-template #characterTemplate let-item>
        <nz-list-item [nzActions]="[selectAction]">
          <ng-template #selectAction>
            <button (click)="selectCharacter(item)" nz-button nzType="primary">{{'COMMON.Select' | translate}}</button>
          </ng-template>
          <nz-list-item-meta
            [nzAvatar]="item.Avatar"
            [nzTitle]="nzTitle">
            <ng-template #nzTitle>
              <span>{{item.Name}}</span>
            </ng-template>
          </nz-list-item-meta>
        </nz-list-item>
      </ng-template>
    </nz-list>
  </div>

  <nz-divider [nzText]="'Or' | translate"></nz-divider>

  <nz-form-item>
    <nz-form-label nzFor="LodestoneId" nzRequired>{{'LodestoneId' | translate}}
    </nz-form-label>
    <input [formControl]="lodestoneId" id="LodestoneId" nz-input type="number">
  </nz-form-item>
  <div *ngIf="lodestoneIdCharacter$ | async as lodestoneIdCharacter">
    <nz-list>
      <nz-list-item [nzActions]="[selectAction]">
        <ng-template #selectAction>
          <button (click)="selectCharacter(lodestoneIdCharacter)" nz-button
                  nzType="primary">{{'COMMON.Select' | translate}}</button>
        </ng-template>
        <nz-list-item-meta
          [nzAvatar]="lodestoneIdCharacter.Avatar"
          [nzTitle]="nzTitle">
          <ng-template #nzTitle>
            <span>{{lodestoneIdCharacter.Name}}</span>
          </ng-template>
        </nz-list-item-meta>
      </nz-list-item>
    </nz-list>
  </div>
</div>

<label [(ngModel)]="useAsDefault" nz-checkbox>{{'Use_as_default_character' | translate}}</label>

<div *ngIf="mandatory">
  <nz-divider [nzText]="'Or' | translate"></nz-divider>

  <button (click)="logOut()" nz-button nzBlock nzType="danger">
    {{'Disconnect_and_anonymous' | translate}}
  </button>
</div>
<ng-template #loading>
  <nz-spin></nz-spin>
</ng-template>
