export const zhActions = [
  { zh: '制作', en: 'Basic Synthesis' },
  { zh: '中级制作', en: 'Standard Synthesis' },
  { zh: '模范制作III', en: 'Careful Synthesis III' },
  { zh: '模范制作II', en: 'Careful Synthesis II' },
  { zh: '模范制作', en: 'Careful Synthesis' },
  { zh: '高速制作II', en: 'Rapid Synthesis II' },
  { zh: '高速制作', en: 'Rapid Synthesis' },
  { zh: '坚实制作', en: 'Flawless Synthesis' },
  { zh: '渐进', en: 'Piece by Piece' },
  { zh: '注视制作', en: 'Focused Synthesis' },
  { zh: '风之印记', en: 'Brand of Wind' },
  { zh: '火之印记', en: 'Brand of Fire' },
  { zh: '冰之印记', en: 'Brand of Ice' },
  { zh: '土之印记', en: 'Brand of Earth' },
  { zh: '雷之印记', en: 'Brand of Lightning' },
  { zh: '水之印记', en: 'Brand of Water' },
  { zh: '风之印记', en: 'Name of the Wind' },
  { zh: '火之美名', en: 'Name of Fire' },
  { zh: '冰之美名', en: 'Name of Ice' },
  { zh: '土之美名', en: 'Name of Earth' },
  { zh: '雷之美名', en: 'Name of Lightning' },
  { zh: '水之美名', en: 'Name of Water' },
  { zh: '加工', en: 'Basic Touch' },
  { zh: '中级加工', en: 'Standard Touch' },
  { zh: '上级加工', en: 'Advanced Touch' },
  { zh: '仓促II', en: 'Hasty Touch II' },
  { zh: '仓促', en: 'Hasty Touch' },
  { zh: '比尔格的祝福', en: 'Byregot\'s Blessing' },
  { zh: '集中加工', en: 'Precise Touch' },
  { zh: '比尔格的技巧', en: 'Byregot\'s Brow' },
  { zh: '专心加工', en: 'Patient Touch' },
  { zh: '俭约加工', en: 'Prudent Touch' },
  { zh: '起始准备', en: 'Initial Preparations' },
  { zh: '注视加工', en: 'Focused Touch' },
  { zh: '内静', en: 'Inner Quiet' },
  { zh: '稳手II', en: 'Steady Hand II' },
  { zh: '稳手', en: 'Steady Hand' },
  { zh: '新颖II', en: 'Ingenuity II' },
  { zh: '新颖', en: 'Ingenuity' },
  { zh: '阔步', en: 'Great Strides' },
  { zh: '俭约II', en: 'Waste Not II' },
  { zh: '俭约', en: 'Waste Not' },
  { zh: '改革', en: 'Innovation' },
  { zh: '安逸', en: 'Comfort Zone' },
  { zh: '掌握II', en: 'Manipulation II' },
  { zh: '掌握', en: 'Manipulation' },
  { zh: '回收', en: 'Reclaim' },
  { zh: '精修II', en: 'Master\'s Mend II' },
  { zh: '精修', en: 'Master\'s Mend' },
  { zh: '松弛', en: 'Rumination' },
  { zh: '秘诀', en: 'Tricks of the Trade' },
  { zh: '观察', en: 'Observe' },
  { zh: '坚信', en: 'Muscle Memory' },
  { zh: '坚实的心得', en: 'Maker\'s Mark' },
  { zh: '工作小调', en: 'Whistle While You Work' },
  { zh: '称心', en: 'Satisfaction' },
  { zh: '革新', en: 'Innovative Touch' },
  { zh: '妮美雅的纺车', en: 'Nymeia\'s Wheel' },
  { zh: '比尔格的奇迹', en: 'Byregot\'s Miracle' },
  { zh: '匠心独具', en: 'Trained Hand' },
  { zh: '专精绝技：闲静', en: 'Specialty: Reflect' },
  { zh: '专精绝技：安稳', en: 'Specialty: Refurbish' },
  { zh: '专精绝技：巩固', en: 'Specialty: Reinforce' }
];
